/* @import url('https://fonts.googleapis.com/icon?family=Material+Icons'); */
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0');

.material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}

@font-face {
  font-family: 'oie-icons';
  src:  url('assets/fonts/oie-icons.eot?82hyvn');
  src:  url('assets/fonts/oie-icons.eot?82hyvn#iefix') format('embedded-opentype'),
    url('assets/fonts/oie-icons.ttf?82hyvn') format('truetype'),
    url('assets/fonts/oie-icons.woff?82hyvn') format('woff'),
    url('assets/fonts/oie-icons.svg?82hyvn#oie-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.graphHeaderContainer {
    display: none;
}

.graphHeader {
    display: none;
    background: #FFF;
    padding: 20px;
}

.projectModuleHeaderTitleTooltip {
    display: flex;
}

.downloadBtn {
    display: flex;
    justify-content: space-between;
    width: 170px;
}

.downloadBtn span {
    margin-left: 5px;
}

.graphToImgBtn {
    cursor: pointer;
    padding: 8px;
    margin-top: 16px;
    /* display: inline-block; */
    background: #007bff;
    border-radius: 5px;
    float: right;
    color: #FFF;
}

.graphToImgBtn:hover {
    background: #489bf4;
    text-decoration: none;
}

.csvBtn {
    cursor: pointer;
    padding: 8px;
    margin-top: 16px;
    margin-right: 8px;
    /* display: inline-block; */
    background: #0fc168;
    border-radius: 5px;
    float: right;
    color: #FFF;
}

.csvBtn:hover {
    background: #0cdb74;
    color: #FFF;
    text-decoration: none;
}

@media screen and (max-width: 600px) {
    .graphToImgBtn {
        display: none;
    }
}

.graphToImgBtn p {
    display: flex;
    align-items: center;
    margin: 0;
}

.graphToImgBtn p span {
    margin-left: 5px;
}

.rv-xy-plot__grid-lines__line {
    stroke: #666 !important;
}

.visx-legend-item {
    margin: 5px !important;
}

.visx-legend-item rect {
    stroke: #666 !important;
}

.visxTooltip strong {
    text-transform: uppercase;
}

.visxLegend {
  line-height: 0.9em;
  color: #efefef;
  font-size: 10px;
  font-family: arial;
  padding: 15px;
  border-radius: 8px;
}

.visxLegend > div {
    flex-wrap: wrap;
}

.visxLegendTitle {
    font-size: 1.5em;
    padding-bottom: 20px;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'oie-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twitter:before {
  content: "\ea96";
}

[class^="icon-"]:hover,
[class*=" icon-"]:hover {
    text-decoration: none;
    color: #FF6833;
}

[class^="icon-"] span, [class*=" icon-"] span {
    display: none;
}

* {
    box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logoBig {
    width: 160px;
    height: 60px;
    background: url('./assets/images/oie-logo_big.svg') no-repeat center;
}

.logoBig span {
    display: none;
}

.footer {
    background: #000;
    width: 100%;
    padding: 40px 20px;
}

.footer div {
    padding: 0 10px;
}

.footerRow {
    display: flex;
    justify-content: space-between;
}

.footer nav ul {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.footer nav ul li {
    float: left;
    border-bottom: 2px solid #FF6833;
    margin: 0 5px;
    padding: 8px 0;
}

.footer nav ul li a {
    display: block;
    color: #FFF;
    width: 100%;
    height: 100%;
    font-weight: 500;
}

.footer nav ul li a:hover {
    text-decoration: none;
}

.footerText {
    font-weight: 500;
}

.redText {
    color: #FF6833;
}

.whiteText {
    color: #FFF;
}

.smallText {
    font-size: 12px;
}

.footer p a {
    color: #FFF;
    font-weight: 500;
}

#disclaimer {
    max-width: 800px;
}

#oxfam {
    width: 116px;
    height: 80px;
    background: url('./assets/images/oxfam.svg') no-repeat center;
}

.twoColumns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 100px;
}

hr {
    margin: 50px 180px;
}

@media screen and (max-width: 600px) {
    .footerRow {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .footer nav ul {
        display: block;
        padding: 20px 0;
    }

    .footer nav ul li {
        float: none;
        margin: 0;
    }
}

@media print {
    .footer {
        display: none;
    }
}
