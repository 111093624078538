@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
    background: #FFF;
  color: #444;
  cursor: pointer;
  padding: 6px 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

.chevron-circle {
    fill: none;
}

.chevron-pointer {
    stroke: #009B84;
}

.active-accordion .chevron-circle {
    fill: #009B84;
}

.active-accordion .chevron-pointer {
    stroke: #FFF;
}

/* Style the accordion content title */
.accordion__title {
  font-weight: 200;
  font-size: 2em;
  color: #009883;
  margin-left: 20px;
  margin-bottom: 0;
}

/* Style the accordion chevron icon */
.accordion__icon {
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(-180deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
    background-color: white;
    overflow: hidden;
    transition: max-height 0.6s ease;
    padding-left: 30%;
}

/* Style the accordion content text */
.accordion__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}

@media screen and (max-width: 600px) {
    .accordion__title {
        font-size: 0.875em;
        font-weight: 400;
    }
}
