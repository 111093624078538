@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:200,300,400,500,700&display=swap');

* {
    font-family: 'IBM Plex Sans', sans-serif;
}

.noPaddingTop {
    padding-top: 0 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.darkblue-bg {
    background: #05001F;
    color: #FFF;
}

.black-bg {
    background: #000;
    color: #FFF;
}

.darkgray-bg {
    background-color: #1F2121;
}

.green-bg {
    background-color: #009883;
}

.wrapper {
    margin: 0 auto;
    width: 100%;
    /* height: 100%; */
    padding: 80px 50px;
}

.wrapperCentered {
    margin: 0 auto;
    width: 100%;
    padding: 80px 50px 0 50px;
}

.uppercase {
    text-transform: uppercase;
}

.bold {
    font-weight: 700 !important;
}

.black {
    color: #2F2F2F !important;
}

.white {
    color: #FFF;
}

.orange {
    color: #B05900 !important;
}

.electricOrange {
    color: #DB6C00 !important;
}

.banner {

}

.banner-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}

.banner-wrapper-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0 180px;
    text-align: left;
}

.banner-cta {
    display: inline-block;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1.25em;
    font-weight: 500;
    color: #000;
    padding: 5px 25px;
    border: 2px solid #000;
    border-radius: 50px;
    cursor: pointer;
    margin-top: 50px;
    align-self: flex-start;
}

.paragraph .banner-cta {
    display: table;
}

.banner-cta:hover {
    text-decoration: none;
    color: #000;
}

.white-cta {
    color: #FFF !important;
    border: 2px solid #FFF;
}

.home-first-slide-one {
    background: url('./assets/images/oie-home-first_slide-one_bg.svg') no-repeat;
    background-position: center right;
    background-size: 50%;
}

.home-first-slide-two {
    background: url('./assets/images/oie-home-first_slide-two_bg.svg') no-repeat;
    background-position: center right;
    background-size: 50%;
}

.home-second-slide {
    background: url('./assets/images/oie-home-second_slide-bg.svg') no-repeat center;
    background-size: 100%;
}

.home-second-slide-content {
    background: linear-gradient(90deg, rgba(147,78,0,1) 35%, rgba(255,255,255,0) 100%);
}

.home-second-slide-content .banner-cta {
    /* align-self: flex-start; */
}

.home-third-slide {
    background: url('./assets/images/oie-home-third-slide-bg.png') no-repeat center;
    background-position: center bottom;
    background-size: cover;
    padding: 100px 0;
}

.home-third-slide-content {

}

.home-third-slide-content .title {
    color: #000;
    max-width: none;
}

.home-third-slide-content .paragraph {
    color: #000;
    width: 80%;
    max-width: 80%;
}

.subtitle {
    display: inline-block;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1.375em;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 60px;
}

.title {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 3vw;
    font-weight: 200;
    color: #FFFFFF;
    max-width: 80%;
    margin-bottom: 40px;
}

.small-title {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 2vw;
    font-weight: 200;
    color: #FFFFFF;
    max-width: 80%;
    margin-bottom: 50px;
}

.paragraph {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1em;
    font-weight: 400;
    color: #FFFFFF;
    max-width: 50%;
}

.carousel-nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 180px;
    height: 100%;
}

.carousel-nav-wrapper-left {
    left: 0;
}

.carousel-nav-wrapper-right {
    right: 0;
}

.carousel-nav-btn {
    background: none;
    border: 0;
    padding: 0;
}

.carousel-nav-btn svg {
    width: 25px;
    height: 48px;
}

.carousel-nav-btn svg .st0 {
    fill: #666;
}

.printables {
    display: none;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    padding: 50px 0;
    font-family: 'IBM Plex Sans', sans-serif;
    color: #000;
}

.timelinePrintables {
    display: none;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    padding: 50px 0;
    font-family: 'IBM Plex Sans', sans-serif;
    color: #000;
}

.timelinePrintables h2,
.printables h2 {
    font-size: 0.875em;
    font-weight: 400;
    margin-bottom: 10px;
}

.timelinePrintables p,
.printables p {
    font-size: 0.75em;
    font-weight: 300;
    margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
    .home-first-slide-one {
        background-position: center bottom;
        background-size: 100%;
    }

    .wrapper {
        padding: 80px 20px 0 20px;
    }

    .wrapperCentered {
        margin: 0 auto;
        width: 100%;
        padding: 50px 20px 0 20px;
    }

    .subtitle {
        font-size: 1em;
        margin-bottom: 20px;
    }

    .title {
        font-size: 2.5em;
        max-width: none;
    }

    .paragraph {
        font-size: 0.875em;
        max-width: none;
    }

    .banner-wrapper {
        /* padding-top: 100px; */
        overflow: auto;
    }

    .banner-wrapper-content {
        padding: 0 20px;
        text-align: left;
    }

    .carousel-nav-wrapper {
        display: none;
    }
}

@media print {
    .section-title {
        font-size: 2em !important;
    }

    #disclaimer,
    .whiteText {
        color: #000 !important;
    }

    .hideInPrint,
    .still-looking {
        display: none !important;
    }

    .white {
        color: #000;
    }

    .darkblue-bg,
    .black-bg,
    .green-bg {
        background-color: transparent;
    }

    .wrapper {
        padding: 0 50px;
    }

    .section {
        break-before: always;
        page-break-before: always;
    }

    .printables-logo {
        display: block;
        height: 30px;
        margin-bottom: 20px;
    }

    .projectInformationWrapper {
        padding: 50px 0 0 0;
    }

    .projectInformation h2 {
        margin: 20px 0 30px 0;
    }

    .timeline-wrapper {
        display: none;
    }

    .timelinePrintables,
    .printables {
        display: grid;
    }
}
