.governanceDisclaimer {
    padding: 80px 180px 0 180px;
}

.governanceDisclaimerWrapper {
    column-count: 2;
    column-gap: 100px;
    color: #FFF;
}

.governanceTimeline {
    color: #FFF;
}

@media screen and (max-width: 600px) {
    .governanceDisclaimer {
        padding: 50px 20px 0 20px;
    }

    .governanceDisclaimer p {
        column-count: 1;
        column-gap: 0;
    }
}
