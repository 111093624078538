.gc-image {
    background: url('./assets/images/oie-gc.svg') no-repeat center;
    width: 40%;
    height: 0;
    /* padding-bottom: 40%; */
    padding-bottom: 50%;
}

#corporativeGovernance {
    /* background: url('corporativeGovernance-bg.svg') no-repeat; */
    background-size: 100%;
    background-position: top center;
}

#stockViewer p {
    margin: 0;
    margin-bottom: 0 !important;
}

#SEC a {
    color: #2eff8b;
    text-decoration: underline;
}

#SEC a:hover {
    color: #FFF;
}

.ocre {
    background-color: #964d18;
    color: #FFF;
}

.ocre .title {
    color: #fda639 !important;
}

.green {
    background-color: #003c3c;
    color: #FFF;
}

.green .section-title,
.green h3 {
    color: #2eff8b !important;
}

.mapa-actores {
    width: 100%;
}

#corporativeGovernance .title {
    width: 100%;
    max-width: none;
}

#corporativeGovernance .paragraph {
    max-width: none;
}

#corporativeGovernance .banner-wrapper {
    padding-bottom: 80px;
}

#corporativeGovernance .banner-wrapper-content {
    flex-direction: row;
    padding: 200px 30px 0 180px;
}

#corporativeGovernance .banner-wrapper-content-full {
    display: flex;
    flex-direction: row;
}

#corporativeGovernance .banner-wrapper-content div {
    width: 50%;
}

#corporativeGovernance .banner-wrapper-content-full div {
    width: 50%;
}

#corporativeGovernance .banner-info {
    width: 60%;
    padding-right: 20px;
}

#corporativeGovernance .wrapper {
}

#corporativeGovernance .wrapper-symmetrical {
    width: 100%;
    padding: 0 180px 80px 180px;
}

#corporativeGovernance .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
}

#corporativeGovernance .section div {
}

.gc-image {
    width: 40%;
    height: 0;
    /* padding-bottom: 40%; */
    padding-bottom: 50%;
}

.paddingTop {
    padding-top: 60px !important;
}

@media screen and (max-width: 600px) {
    #corporativeGovernance .banner-wrapper-content {
        padding: 100px 20px 0 20px;
        flex-direction: column-reverse;
    }

    .gc-image {
        padding-bottom: 100%;
    }

    #corporativeGovernance .banner-wrapper-content div {
        width: 100%;
    }

    #corporativeGovernance .grid {
        grid-template-columns: 1fr;
    }

    #corporativeGovernance .wrapper,
    #corporativeGovernance .wrapper-symmetrical {
        padding: 20px;
    }

    #corporativeGovernance .banner-wrapper-content-full {
        flex-direction: column;
    }

    #corporativeGovernance .banner-wrapper-content-full div {
        width: 100%;
    }

    .small-title {
        font-size: 1.5em;
    }
}


.printables-logo {
    display: none;
}

.projectInformationWrapper {
    padding: 200px 0 100px 0;
}

.projectInformation h1 {
    font-size: 3.75em;
    font-weight: 300;
}

.projectInformation h2 {
    margin: 150px 0 80px 0;
}

.projectInformation h3 {
    font-weight: 700;
    font-size: 0.875em;
    color: #934E00;
}

.projectInformation p {
    font-weight: 300;
    font-size: 1.5em;
}

.subtitle-large {
    font-weight: 300;
}

.subtitle-medium {
    font-weight: 700;
}

.subtitle-small {
    font-weight: 700;
}

.content-large {
    font-size: 2.25em !important;
}

.content-medium {
    font-size: 2.125em;
}

.content-small {
    font-size: 1.5em;
}

.project-information-content {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row-gap: 20px;
}

/* .project-information-subcontent {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row-gap: 20px;
} */

@media screen and (max-width: 600px) {
    .projectInformationWrapper {
        padding: 100px 0 50px 0;
    }

    .projectInformation h1 {
        font-size: 2.5em;
    }

    .projectInformation h2 {
        font-size: 1.5em;
        margin: 50px 0 50px 0;
    }

    .projectInformation p {
        font-weight: 300;
        font-size: 1.25em;
    }

    .content-large {
        font-size: 2em !important;
    }

    .content-medium {
        font-size: 1.5em;
    }

    .content-small {
        font-size: 1em;
    }

    .project-information-content {
        grid-template-columns: 100%;
    }
}

@media print {
    .printables-logo {
        display: block;
        height: 30px;
        margin-bottom: 20px;
    }

    .projectInformationWrapper {
        padding: 50px 0 0 0;
    }

    .projectInformation h2 {
        margin: 20px 0 30px 0;
    }

    .timeline-wrapper {
        display: none;
    }

    .timelinePrintables,
    .printables {
        display: grid;
    }
}
