.graphWrapper {
    position: relative;
}

.graphContainer {
    background: #000;
}

.graphHeaderContainer {
    display: none;
}

.visx-annotation-connector {
    stroke-width: 2px;
}

.graphHeader {
    display: none;
    background: #FFF;
    padding: 20px;
}

.graphTitle {
    color: #000;
    padding-left: 20px;
}

#cm {
    background: rgb(165,127,255);
    background: linear-gradient(180deg, rgba(165,127,255,1) 0%, rgba(186,219,255,1) 60%, rgba(255,255,255,0) 100%);
    background-size: 100% 500px;
    background-repeat: no-repeat;
}

#cm .title {
    /* width: 50%; */
    max-width: none;
}

#cm .paragraph {
    max-width: none;
}

#cm .banner-wrapper {
    background: url('./assets/images/oie-cm.svg') no-repeat;
    background-size: contain;
    background-position: center 100px;
    padding-bottom: 80px;
}

#cm .banner-wrapper-content {
    padding: 200px 180px 0 180px;
}

#cm .banner-wrapper-content div {
    /* width: 100%; */
}

#cm .banner-info {
    width: 60%;
    padding-right: 20px;
}

#cm .wrapper {
    width: 100%;
}

#cm .wrapper-symmetrical {
    width: 100%;
    padding: 0 180px 80px 180px;
}

#cm .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
}

#cm .twoColumns {
    align-items: center;
}

.cmLegends img {
    width: 20px;
}

.cmDisclaimerWrapper {
    margin-top: 180px;
    /* column-count: 2; */
    column-gap: 100px;
}

.water-indicators h2 {
    height: 50px;
}

.water-indicators p {
    font-size: 0.875em;
}

.water-indicators img {
    margin-right: 10px;
}

.water-indicators > div {
    display: flex;
    align-items: flex-start;
}

.paddingTop {
    padding-top: 60px !important;
}


.cmWebmap {
    height: calc(100vh - 80px);
}

.cmWebmapWrapper {
    background: #C1BBFF;
    padding: 80px 100px;
}

.cmWebmapWrapper .section-title {
    margin-bottom: 50px;
}

.cmWebmapWrapperContent {
    display: grid;
    grid-template-columns: 30% 70%;
}

@media screen and (max-width: 600px) {
    #cm .title {
        font-size: 1.5em;
        width: 60%;
    }

    .cmDisclaimerWrapper {
        margin-top: 0;
    }

    #cm .banner-wrapper-content {
        padding: 100px 20px 0 20px;
        flex-direction: column-reverse;
    }

    .cc-image {
        padding-bottom: 100%;
    }

    #cm .banner-wrapper-content div {
        width: 100%;
    }

    #cm .grid {
        grid-template-columns: 1fr;
    }

    #cm .wrapper,
    #cm .wrapper-symmetrical {
        display: block;
        padding: 0 20px 80px;
    }

    .cmWebmapWrapper {
        padding: 80px 20px;
    }

    .cmWebmapWrapperContent {
        display: block;
    }

    .small-title {
        font-size: 1.5em;
    }
}
