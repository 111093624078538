/*************************************************
Menu hamburger begin
*************************************************/

.closed span, .closed span:after, .closed span:before {
    background-color: #FFF;
}

.opened span:after, .opened span:before {
    background-color: #FFF;
}

.c-hamburger {
    background: none;
    display: block;
    position: absolute;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 25px;
    height: 25px;
    font-size: 0;
    text-indent: -9999px;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
}

.c-hamburger:focus {
    outline: none;
}

.c-hamburger span {
    display: block;
    position: absolute;
    top: 11px;
    left: 0px;
    right: 0px;
    height: 3px;
    -webkit-transition: 0.1s;
    -moz-transition: 0.1s;
    -ms-transition: 0.1s;
    -o-transition: 0.1s;
    transition: 0.1s;
}

.c-hamburger span::before,
.c-hamburger span::after {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 3px;
    content: "";
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.c-hamburger span::before {
    top: -11px;
}

.c-hamburger span::after {
    bottom: -11px;
}

.c-hamburger--htx {
}

.c-hamburger--htx span {
    -webkit-transition: background 0s 0.3s;
    transition: background 0s 0.3s;
}

.c-hamburger--htx span::before,
.c-hamburger--htx span::after {
    -webkit-transition-duration: 0.3s, 0.3s;
    transition-duration: 0.3s, 0.3s;
    -webkit-transition-delay: 0.3s, 0s;
    transition-delay: 0.3s, 0s;
}

.c-hamburger--htx span::before {
    -webkit-transition-property: top, -webkit-transform;
    transition-property: top, transform;
}

.c-hamburger--htx span::after {
    -webkit-transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
}

/* active state, i.e. menu open */
.c-hamburger--htx.is-active {
}

.c-hamburger--htx.is-active span {
    -webkit-animation: center-span 0.3s;
}

.c-hamburger--htx.is-active span::before {
    top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.c-hamburger--htx.is-active span::after {
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.c-hamburger--htx.is-active span::before,
.c-hamburger--htx.is-active span::after {
    -webkit-transition-delay: 0s, 0.3s;
    transition-delay: 0s, 0.3s;
}

@-webkit-keyframes center-span {
  /* You could think of as "step 1" */
  0% {
    background: #6EDBD5;
  }
  /* You could think of as "step 2" */
  100% {
    background: none;
  }
}

/*


.c-hamburger--htx.is-active span::before {
    top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.c-hamburger--htx.is-active span::after {
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
*/

/*************************************************
Menu hamburger end
*************************************************/
