.source-wrapper {

}

.source-text {
    color: white;
    padding: 15px;
    margin-bottom: 0;
    cursor: pointer;
}

.source-text span {
    font-size: 14px;
}

.sourceFeedback {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: center;
    z-index: 20000;
}

.sourceFeedbackInner {
    background: rgb(0, 188, 103);
    color: #FFF;
    padding: 5px;
    border-radius: 0 0 5px 5px;
}

.sourceFeedbackInner p {
    margin-bottom: 0;
}