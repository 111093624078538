
.colorLegend {
  display: inline-grid;
  grid-template-columns: auto auto auto auto auto;
  overflow-x: none;
  grid-column-gap: 4%;

  float: none;
}

.colorLegend span {
  color: #FFF;
}

@media screen and (max-width: 600px) {

  .colorLegend {
      display: grid;
      grid-template-columns: 100%;
      grid-column-gap: 0;
      float: none;
  }
}

@media print {
    .colorLegend span {
        color: #000;
    }
}
