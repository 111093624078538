.extractionsTreemapHeader {
    width: 100%;
    height: 100px;
    float: left;
}

.treemap {
    float: left;
}

.treemapLegend {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-column-gap: 4%;
    float: left;
}

.treemapLegend span {
    color: #FFF;
}

@media screen and (max-width: 600px) {
    .extractionsTreemapHeader {
        height: 150px;
        float: none;
    }

    .treemap {
        float: none;
    }

    .treemapLegend {
        display: grid;
        grid-template-columns: 100%;
        grid-column-gap: 0;
        float: none;
    }
}
