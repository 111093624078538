.oro .st0 {
    fill: #D1FF55;
}

.cobre .st0 {
    fill: #A315FF;
}

.plomo .st0 {
    fill: #008E35;
}

.projectMineralsWrapper {
}

.projectMineralsHeader {
    margin-bottom: 120px;
}

.projectMineralsWrapper h1 {
    font-size: 1.875em;
    font-weight: 500;
    color: #FFF;
}

.projectMineralsWrapper h2 {
    font-weight: 400;
    font-size: 1em;
    color: #FFF;
}

.projectMineralsWrapper h3 {
    font-weight: 700;
    font-size: 0.875em;
    color: #934E00;
}

.projectMineralsWrapper p {
    font-weight: 300;
    font-size: 1em;
    margin-bottom: 0;
    color: #FFF;
}

.projectMineralsWrapper p span {
    margin-right: 10px;;
}

.project-minerals-content p span svg {
    height: 60px;
}

.subtitle-large {
    font-weight: 300;
}

.subtitle-medium {
    font-weight: 700;
}

.subtitle-small {
    font-weight: 700;
}

.content-large {
    font-size: 2.25em !important;
}

.content-medium {
    font-size: 2.125em;
}

.content-small {
    font-size: 1.5em;
}

.project-minerals-content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-row-gap: 20px;
}

/* .project-information-subcontent {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row-gap: 20px;
} */

@media screen and (max-width: 600px) {
    .projectMineralsWrapper {
        padding: 50px 0;
    }

    .projectMineralsWrapper h1 {
        font-size: 2.5em;
    }

    .projectMineralsWrapper h2 {
        font-size: 1.5em;
        margin: 50px 0 50px 0;
    }

    .projectMineralsWrapper p {
        font-weight: 300;
        font-size: 1.25em;
    }

    .content-large {
        font-size: 2em !important;
    }

    .content-medium {
        font-size: 1.5em;
    }

    .content-small {
        font-size: 1em;
    }

    .project-information-content {
        grid-template-columns: 100%;
    }
}

@media print {
    .projectMineralsWrapper p {
        color: #000;
    }

    .projectMineralsWrapper {
        padding: 50px 0 0 0;
    }

    .projectMineralsWrapper h2 {
        margin: 20px 0 30px 0;
    }

    .timeline-wrapper {
        display: none;
    }

    .printables {
        display: grid;
    }
}
