a {
    color: #000;
}

a:hover {
    color: #000;
}

.sidebar {
    padding: 60px;
}

.sidebar h1 {
    font-size: 16px;
    text-transform: uppercase;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar ul li {
    font-size: 16px;
    margin-bottom: 10px;
}

.catalogue-content {
    width: 60%;
}

.catalogue-content h2 {
    font-weight: 300;
    font-size: 14px;
}

.catalogue-content input {
    width: 100%;
    padding: 8px;
}

.tags {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin-top: 20px;
}

.tags li {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 300;
    padding: 3px;
    background: #EEE;
}

.catalogue-entries {
    padding-top: 40px;
}

.catalogue-entry {
    padding: 20px;
    border-top: 1px solid #333;
}

.catalogue-entry h1 {
    font-size: 24px;
    font-weight: 400;
}

.catalogue-entry p {
    max-width: 70%;
    font-size: 12px;
}

#catalogue {
    display: flex;
    padding: 160px 100px 100px 100px;
}

#catalogue .title {
    width: 100%;
    max-width: none;
}

#catalogue .paragraph {
    max-width: none;
}

#catalogue .banner-wrapper {
    padding-bottom: 80px;
}

#catalogue .banner-wrapper-content {
    flex-direction: row;
    padding: 200px 30px 0 180px;
}

#catalogue .banner-wrapper-content div {
    width: 50%;
}

#catalogue .banner-info {
    width: 60%;
    padding-right: 20px;
}

#catalogue .wrapper {
    width: 100%;
    padding: 0 80px 80px 180px;
}

#catalogue .wrapper-symmetrical {
    width: 100%;
    padding: 0 180px 80px 180px;
}

#catalogue .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
}

.cc-image {
    background: url('./assets/images/oie-cc.svg') no-repeat center;
    width: 40%;
    height: 0;
    /* padding-bottom: 40%; */
    padding-bottom: 50%;
}

.water-indicators h2 {
    height: 50px;
}

.water-indicators p {
    font-size: 0.875em;
}

.water-indicators img {
    margin-right: 10px;
}

.water-indicators > div {
    display: flex;
    align-items: flex-start;
}

.paddingTop {
    padding-top: 60px !important;
}

@media screen and (max-width: 600px) {
    #catalogue .banner-wrapper-content {
        padding: 100px 20px 0 20px;
        flex-direction: column-reverse;
    }

    .cc-image {
        padding-bottom: 100%;
    }

    #catalogue .banner-wrapper-content div {
        width: 100%;
    }

    #catalogue .grid {
        grid-template-columns: 1fr;
    }

    #catalogue .wrapper,
    #catalogue .wrapper-symmetrical {
        padding: 0 20px 80px;
    }

    .small-title {
        font-size: 1.5em;
    }
}
