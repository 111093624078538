.aboutUs .section,
.aboutUs .aboutus-banner {
    min-height: 100vh;
}

.aboutus-banner {
    background: linear-gradient(180deg, rgba(168,113,51,1) 0%, rgba(230,118,0,1) 100%);
    padding-top: 100px;
}

.aboutus-banner-content {
    background: url('./assets/images/oie-aboutus-banner-bg.svg') no-repeat right;
    background-size: 80%;
}

.accordions-wrapper {
    padding-top: 80px;
}

table {
    width: 100%;
}

th {
    vertical-align: top;
    text-align: center;
}

th, td {
    padding: 5px;
    vertical-align: top;
}

ul {
    padding: 10px 0 0 20px;
}
