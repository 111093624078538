.webmap {
    height: calc(100vh - 80px);
}

.leaflet-container {
    width: 100%;
}

.popup-title {
    font-size: 1em;
}

.mapFiltersWrapper {
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    max-width: 400px;
    position: absolute;
    padding: 40px;
    top: 0px;
    left: 0px;
    z-index: 1000;
    overflow-y: auto;
}

.tooltipButton {
    color: #000;
}

.mapFiltersWrapper .title {
    font-size: 2em;
    max-width: none;
}

.sidebarHeadline {
    display: flex;
    align-items: center;
}

.projects-legends-list .subtitle,
.map-filters-wrapper .subtitle {
    font-weight: 200;
    margin-bottom: 20px;
}

.projects-legends-list {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 120px;
    right: 40px;
    width: 240px;
    padding: 30px;
    z-index: 1000;
}

.projects-legends-list img {
    height: 16px;
    margin-right: 8px;
}

.projects-legends-list p:last-child {
    margin-bottom: 0;
}

.mapFilters {
    display: flex;
    flex-direction: column;
}

.map-filter {
    width: 100%;
    margin-bottom: 20px;
}

.large-btn {
    display: inline-block;
    background: #DB6C00;
    width: 50%;
    max-width: 168px;
    padding: 6px 0;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #DB6C00;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1em;
    font-weight: 500;
    color: #FFF !important;
}

.open-filters-btn-wrapper {
    display: none;
    width: 100%;
    position: absolute;
    z-index: 10000;
    bottom: 24px;
    text-align: center;
    padding: 0 20px;
}

.toggle-filters-btn {
    display: none;
}

.projects-list {
    width: 100%;
}

.projects-list-wrapper {
    height: 200px;
    overflow-y: auto;
}

.projects-list-wrapper a {
    color: #333;
}

.projects-list-wrapper a:hover {
    text-decoration: none;
    color: #B05900;
}

.infoSidebarToggle {
    position: absolute;
    width: 30px;
    background: #000;
}

.projectsLegendsList .subtitle {
    font-weight: 200;
    margin-bottom: 20px;
}

.projectsLegendsList {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 120px;
    right: 40px;
    width: 240px;
    padding: 30px;
    z-index: 1000;
}

.projectsLegendsList img {
    height: 16px;
    margin-right: 8px;
}

.projectsLegendsList p:last-child {
    margin-bottom: 0;
}

@media screen and (max-width: 600px) {
    .webmap {
        height: 50vh;
    }

    .open-filters-btn-wrapper {
        display: block;
    }

    .toggle-filters-btn {
        display: inline-block;
    }

    .map-filters-wrapper {
        display: none;
        top: 0;
        left: 0;
        height: 100%;
        padding: 80px 20px;
    }

    .map-filters-wrapper .title {
        font-size: 1.5em;
    }

    .projects-list-wrapper {
        height: 155px;
        border-bottom: 20px solid rgba(0,0,0,0);
    }

    .projects-legends-list {
        width: 180px;
        padding: 20px;
    }

    .projects-legends-list p {
        font-size: 0.75em;
    }
}
