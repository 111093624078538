#seg {
    background: url('./assets/images/oie-seg-bg.svg') no-repeat;
    background-size: 100%;
    background-position: top center;
}

#seg .title {
    width: 100%;
    max-width: none;
}

#seg .paragraph {
    max-width: none;
}

#seg .banner-wrapper {
    padding-bottom: 80px;
}

#seg .banner-wrapper-content {
    flex-direction: row;
    padding: 200px 30px 0 180px;
}

#seg .banner-wrapper-content div {
    width: 50%;
}

#seg .banner-info {
    width: 60%;
    padding-right: 20px;
}

#seg .wrapper {
    width: 100%;
    padding: 0 80px 80px 180px;
}

#seg .wrapper-symmetrical {
    width: 100%;
    padding: 0 180px 80px 180px;
}

#seg .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
}

.cc-image {
    background: url('./assets/images/oie-cc.svg') no-repeat center;
    width: 40%;
    height: 0;
    /* padding-bottom: 40%; */
    padding-bottom: 50%;
}

.water-indicators h2 {
    height: 50px;
}

.water-indicators p {
    font-size: 0.875em;
}

.water-indicators img {
    margin-right: 10px;
}

.water-indicators > div {
    display: flex;
    align-items: flex-start;
}

.paddingTop {
    padding-top: 60px !important;
}

@media screen and (max-width: 600px) {
    #seg .banner-wrapper-content {
        padding: 100px 20px 0 20px;
        flex-direction: column-reverse;
    }

    .cc-image {
        padding-bottom: 100%;
    }

    #seg .banner-wrapper-content div {
        width: 100%;
    }

    #seg .grid {
        grid-template-columns: 1fr;
    }

    #seg .wrapper,
    #seg .wrapper-symmetrical {
        padding: 0 20px 80px;
    }

    .small-title {
        font-size: 1.5em;
    }
}
