#masthead {
    display: flex;
    background: #000;
    width: 100%;
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10001;
    -webkit-transition: padding 0.3s;
    -moz-transition: padding 0.3s;
    -ms-transition: padding 0.3s;
    -o-transition: padding 0.3s;
    transition: padding 0.3s;
    pointer-events: none;

    justify-content: space-between;
}

#siteLogo {
    display: block;
    background: url('./assets/images/oie-logo.svg') no-repeat left;
    width: 380px;
    height: 30px;
    pointer-events: all;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    pointer-events: all;
}

.small-header #siteLogo {
    background: url('./assets/images/oie-logo_small.svg') no-repeat left;
}

#siteLogo span {
    display: none;
}

.black > * {
    color: #000 !important;
}

/* header nav {
    float: right;
}

header nav ul {
    list-style-type: none;
}

header nav ul li {
    float: left;
    padding-left: 10px;
} */

.site-branding {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 20px 40px;
}

.logo {
    height: 100%;
    -webkit-transition: height 0.3s;
    -moz-transition: height 0.3s;
    -ms-transition: height 0.3s;
    -o-transition: height 0.3s;
    transition: height 0.3s;
    pointer-events: auto;
}

.small-header {
}

.main-navigation {
    display: block;
    z-index: 10003;
    overflow-x: hidden;
    pointer-events: auto;
    padding: 0 40px;

    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.main-navigation ul {
    padding: 0;
}

.main-navigation > ul {
    display: flex;
    list-style-type: none;
    margin: 0;
}

.main-navigation ul li {
    margin: 0 8px;
    padding: 18px 0;
}

.main-navigation ul li p {
    display: block;
    width: 100%;
    text-decoration: none;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1em;
    font-weight: 400;
    color: #FFFFFF;
    padding: 10px 0;
    cursor: pointer;
    margin: 0;

    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.main-navigation ul li p .arrow {
    color: #DDD;
    font-size: 0.75em;
}

.main-navigation ul li:hover ul {
    display: flex;
}

.main-navigation ul li a {
    display: block;
    width: 100%;
    text-decoration: none;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1em;
    font-weight: 400;
    color: #FFFFFF;
    padding: 10px 0;

    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.main-navigation ul li a span {
    padding-left: 48px;
}

.main-navigation ul li ul {
    background: #000;
    position: absolute;
    display: none;
    flex-direction: column;
    list-style-type: none;
}

.main-navigation ul li ul li {
    display: inline-block;
}

.menu-btn-wrapper {
    background: #994300;
    display: none;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 80px;
    position: absolute;
    right: 0px;
    top: 0px;
}

#menu_btn {
    z-index: 10005;
    pointer-events: auto;
}

@media screen and (max-width: 1350px) {
    #masthead {
        display: block;
    }

    .main-navigation {
        width: 0;
        background: rgba(153, 67, 0, 0.85);
        height: calc(100% - 80px);
        position: fixed;
        top: 80px;
        right: 0;
        padding: 0;
    }

    .menu-btn-wrapper {
        display: flex;
    }

    .main-navigation > ul {
        display: block;
        width: 320px;
        margin: 100px 0 0 0;
        padding: 0 15px;
    }

    .main-navigation ul li {
        float: none;
        margin: 0;
        padding: 0;
        border-bottom: 0.5px solid #FFF;
    }

    .main-navigation ul li p {
        border-bottom: 0.5px solid #FFF;
    }

    .main-navigation ul li p span {
        display: none;
    }

    .main-navigation ul li ul {
        background: transparent;
        display: block;
        position: relative;
    }

    .main-navigation ul li ul li {
        display: block;
        padding-left: 10px;
    }

    .main-navigation ul li ul li:last-child {
        border-bottom: 0;
    }

    .main-navigation ul li p {
        font-size: 1em;
        color: #FFBE8D;
    }

    .main-navigation ul li a {
        font-size: 1em;
        color: #FFBE8D;
    }

    #siteLogo {
        background: url('./assets/images/oie-logo_small.svg') no-repeat left;
    }

    .menu-btn-wrapper {
        width: 60px;
    }

    header {
    }

    .logo {

    }

    .small-header {

    }

    #menu_btn {
        z-index: 10005;
        pointer-events: auto;
    }
}

@media print {
    .logo,
    #masthead,
    #menu_btn {
        display: none;
    }
}
