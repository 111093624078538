.pagination {
    justify-content: center;
    margin-top: 30px;
}

.page-link {
    cursor: pointer;
}

.page-item.active .page-link {
    background-color: #000;
    border-color: #000;
}

.sidebar {
    padding: 60px;
}

.sidebar h1 {
    font-size: 16px;
    text-transform: uppercase;
}

.sidebar ul {
    list-style-type: none;
}

.sidebar ul li {
    font-size: 16px;
    margin-bottom: 10px;
}

.libraryContent {
    /* width: 80%; */
}

.libraryContent h2 {
    font-weight: 300;
    font-size: 14px;
}

.libraryContent input {
    width: 100%;
    padding: 8px;
}

.tags {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin-top: 20px;
}

.tags li {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 300;
    padding: 3px;
    background: #EEE;
}

.library-entries {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.map-entries {
    display: block;
}

.library-entry {
    padding: 20px;
    text-align: center;
}

.library-entry a {
    text-decoration: none;
}

.library-entry h1 {
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 5px 0;
    color: #000;
}

.library-entry h2 {
    font-size: 12px;
    font-weight: 300;
    margin: 0 0 5px 0;
    color: #000;
}

.library-entry h3 {
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 5px 0;
    color: #000;
}

.library-entry p {
    font-size: 8px;
    color: #CCC;
    font-weight: 300;
    margin: 0;
}

.library-entry p strong {
    color: #000;
}

.libraryEntryImage {
    width: 100%;
    background: #EEE;
    margin-bottom: 10px;
}



.mapEntryImage {
    width: 100%;
}

.libraryNav ul {
    display: flex;
    list-style-type: none;
    padding: 0;
}

.libraryNav ul li {
    border: 1px solid #000;
}

.libraryNav ul li:first-child {
    border-radius: 5px 0 0 5px;
}

.libraryNav ul li:last-child {
    border-radius: 0 5px 5px 0;
    border-left: 0;
}

.libraryNav ul li a {
    display: block;
    width: 120px;
    padding: 5px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
}

.libraryNav ul li.current a,
.libraryNav ul li a:hover {
    background: #000;
    color: #FFF;
    text-decoration: none;
}

#libraryFeatured {
    display: flex;
    background: rgb(204,204,204);
    background: linear-gradient(0deg, rgba(204,204,204,1) 0%, rgba(255,255,255,1) 100%);
    height: 250px;
    margin: 0px 0;
}

#libraryFeaturedContent {
    width: 100%;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

#libraryFeaturedContent h1 {
    font-size: 22px;
    font-weight: 400;
}

#libraryFeaturedContent p {
    font-size: 10px;
    margin: 0;
}

#libraryFeaturedContent a {
    font-size: 12px;
    color: #f26a39;
}

.libraryBannerImage {
    width: 100%;
    max-height: 150x;
    max-width: 1250px;
    object-fit: contain;
    grid-column: span 2
}

.libraryBannerImageA {
    width: 100%;
    max-height: 150px;
    grid-column: span 2;
    text-align: center;
}


#library {
    display: flex;
    padding: 160px 100px 100px 100px;
}

@media screen and (max-width: 600px) {
    #library {
        display: flex;
        padding: 100px 30px;
    }

    #libraryFeatured {
        height: auto;
    }

    #libraryFeaturedContent {
        width: 100%;
        padding: 30px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px;
    }

    .library-entries {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
    }

    .page-link {
        font-size: 11px;
    }

    .MuiMasonry-root {
        margin: 0 !important;
    }
}
