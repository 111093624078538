@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:200,300,400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0);
* {
    font-family: 'IBM Plex Sans', sans-serif;
}

.noPaddingTop {
    padding-top: 0 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.darkblue-bg {
    background: #05001F;
    color: #FFF;
}

.black-bg {
    background: #000;
    color: #FFF;
}

.darkgray-bg {
    background-color: #1F2121;
}

.green-bg {
    background-color: #009883;
}

.wrapper {
    margin: 0 auto;
    width: 100%;
    /* height: 100%; */
    padding: 80px 50px;
}

.wrapperCentered {
    margin: 0 auto;
    width: 100%;
    padding: 80px 50px 0 50px;
}

.uppercase {
    text-transform: uppercase;
}

.bold {
    font-weight: 700 !important;
}

.black {
    color: #2F2F2F !important;
}

.white {
    color: #FFF;
}

.orange {
    color: #B05900 !important;
}

.electricOrange {
    color: #DB6C00 !important;
}

.banner {

}

.banner-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}

.banner-wrapper-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0 180px;
    text-align: left;
}

.banner-cta {
    display: inline-block;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1.25em;
    font-weight: 500;
    color: #000;
    padding: 5px 25px;
    border: 2px solid #000;
    border-radius: 50px;
    cursor: pointer;
    margin-top: 50px;
    align-self: flex-start;
}

.paragraph .banner-cta {
    display: table;
}

.banner-cta:hover {
    text-decoration: none;
    color: #000;
}

.white-cta {
    color: #FFF !important;
    border: 2px solid #FFF;
}

.home-first-slide-one {
    background: url(/static/media/oie-home-first_slide-one_bg.675d9f83.svg) no-repeat;
    background-position: center right;
    background-size: 50%;
}

.home-first-slide-two {
    background: url(/static/media/oie-home-first_slide-two_bg.d93e0827.svg) no-repeat;
    background-position: center right;
    background-size: 50%;
}

.home-second-slide {
    background: url(/static/media/oie-home-second_slide-bg.757610c2.svg) no-repeat center;
    background-size: 100%;
}

.home-second-slide-content {
    background: linear-gradient(90deg, rgba(147,78,0,1) 35%, rgba(255,255,255,0) 100%);
}

.home-second-slide-content .banner-cta {
    /* align-self: flex-start; */
}

.home-third-slide {
    background: url(/static/media/oie-home-third-slide-bg.12a62fa9.png) no-repeat center;
    background-position: center bottom;
    background-size: cover;
    padding: 100px 0;
}

.home-third-slide-content {

}

.home-third-slide-content .title {
    color: #000;
    max-width: none;
}

.home-third-slide-content .paragraph {
    color: #000;
    width: 80%;
    max-width: 80%;
}

.subtitle {
    display: inline-block;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1.375em;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 60px;
}

.title {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 3vw;
    font-weight: 200;
    color: #FFFFFF;
    max-width: 80%;
    margin-bottom: 40px;
}

.small-title {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 2vw;
    font-weight: 200;
    color: #FFFFFF;
    max-width: 80%;
    margin-bottom: 50px;
}

.paragraph {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1em;
    font-weight: 400;
    color: #FFFFFF;
    max-width: 50%;
}

.carousel-nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 180px;
    height: 100%;
}

.carousel-nav-wrapper-left {
    left: 0;
}

.carousel-nav-wrapper-right {
    right: 0;
}

.carousel-nav-btn {
    background: none;
    border: 0;
    padding: 0;
}

.carousel-nav-btn svg {
    width: 25px;
    height: 48px;
}

.carousel-nav-btn svg .st0 {
    fill: #666;
}

.printables {
    display: none;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    padding: 50px 0;
    font-family: 'IBM Plex Sans', sans-serif;
    color: #000;
}

.timelinePrintables {
    display: none;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    padding: 50px 0;
    font-family: 'IBM Plex Sans', sans-serif;
    color: #000;
}

.timelinePrintables h2,
.printables h2 {
    font-size: 0.875em;
    font-weight: 400;
    margin-bottom: 10px;
}

.timelinePrintables p,
.printables p {
    font-size: 0.75em;
    font-weight: 300;
    margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
    .home-first-slide-one {
        background-position: center bottom;
        background-size: 100%;
    }

    .wrapper {
        padding: 80px 20px 0 20px;
    }

    .wrapperCentered {
        margin: 0 auto;
        width: 100%;
        padding: 50px 20px 0 20px;
    }

    .subtitle {
        font-size: 1em;
        margin-bottom: 20px;
    }

    .title {
        font-size: 2.5em;
        max-width: none;
    }

    .paragraph {
        font-size: 0.875em;
        max-width: none;
    }

    .banner-wrapper {
        /* padding-top: 100px; */
        overflow: auto;
    }

    .banner-wrapper-content {
        padding: 0 20px;
        text-align: left;
    }

    .carousel-nav-wrapper {
        display: none;
    }
}

@media print {
    .section-title {
        font-size: 2em !important;
    }

    #disclaimer,
    .whiteText {
        color: #000 !important;
    }

    .hideInPrint,
    .still-looking {
        display: none !important;
    }

    .white {
        color: #000;
    }

    .darkblue-bg,
    .black-bg,
    .green-bg {
        background-color: transparent;
    }

    .wrapper {
        padding: 0 50px;
    }

    .section {
        page-break-before: always;
        -webkit-column-break-before: always;
                break-before: always;
        page-break-before: always;
    }

    .printables-logo {
        display: block;
        height: 30px;
        margin-bottom: 20px;
    }

    .projectInformationWrapper {
        padding: 50px 0 0 0;
    }

    .projectInformation h2 {
        margin: 20px 0 30px 0;
    }

    .timeline-wrapper {
        display: none;
    }

    .timelinePrintables,
    .printables {
        display: grid;
    }
}

#masthead {
    display: flex;
    background: #000;
    width: 100%;
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10001;
    transition: padding 0.3s;
    pointer-events: none;

    justify-content: space-between;
}

#siteLogo {
    display: block;
    background: url(/static/media/oie-logo.b87bd6d0.svg) no-repeat left;
    width: 380px;
    height: 30px;
    pointer-events: all;
    transition: all 0.3s;
    pointer-events: all;
}

.small-header #siteLogo {
    background: url(/static/media/oie-logo_small.60eec4b4.svg) no-repeat left;
}

#siteLogo span {
    display: none;
}

.black > * {
    color: #000 !important;
}

/* header nav {
    float: right;
}

header nav ul {
    list-style-type: none;
}

header nav ul li {
    float: left;
    padding-left: 10px;
} */

.site-branding {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 20px 40px;
}

.logo {
    height: 100%;
    transition: height 0.3s;
    pointer-events: auto;
}

.small-header {
}

.main-navigation {
    display: block;
    z-index: 10003;
    overflow-x: hidden;
    pointer-events: auto;
    padding: 0 40px;
    transition: 0.3s;
}

.main-navigation ul {
    padding: 0;
}

.main-navigation > ul {
    display: flex;
    list-style-type: none;
    margin: 0;
}

.main-navigation ul li {
    margin: 0 8px;
    padding: 18px 0;
}

.main-navigation ul li p {
    display: block;
    width: 100%;
    text-decoration: none;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1em;
    font-weight: 400;
    color: #FFFFFF;
    padding: 10px 0;
    cursor: pointer;
    margin: 0;
    transition: 0.3s;
}

.main-navigation ul li p .arrow {
    color: #DDD;
    font-size: 0.75em;
}

.main-navigation ul li:hover ul {
    display: flex;
}

.main-navigation ul li a {
    display: block;
    width: 100%;
    text-decoration: none;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1em;
    font-weight: 400;
    color: #FFFFFF;
    padding: 10px 0;
    transition: 0.3s;
}

.main-navigation ul li a span {
    padding-left: 48px;
}

.main-navigation ul li ul {
    background: #000;
    position: absolute;
    display: none;
    flex-direction: column;
    list-style-type: none;
}

.main-navigation ul li ul li {
    display: inline-block;
}

.menu-btn-wrapper {
    background: #994300;
    display: none;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 80px;
    position: absolute;
    right: 0px;
    top: 0px;
}

#menu_btn {
    z-index: 10005;
    pointer-events: auto;
}

@media screen and (max-width: 1350px) {
    #masthead {
        display: block;
    }

    .main-navigation {
        width: 0;
        background: rgba(153, 67, 0, 0.85);
        height: calc(100% - 80px);
        position: fixed;
        top: 80px;
        right: 0;
        padding: 0;
    }

    .menu-btn-wrapper {
        display: flex;
    }

    .main-navigation > ul {
        display: block;
        width: 320px;
        margin: 100px 0 0 0;
        padding: 0 15px;
    }

    .main-navigation ul li {
        float: none;
        margin: 0;
        padding: 0;
        border-bottom: 0.5px solid #FFF;
    }

    .main-navigation ul li p {
        border-bottom: 0.5px solid #FFF;
    }

    .main-navigation ul li p span {
        display: none;
    }

    .main-navigation ul li ul {
        background: transparent;
        display: block;
        position: relative;
    }

    .main-navigation ul li ul li {
        display: block;
        padding-left: 10px;
    }

    .main-navigation ul li ul li:last-child {
        border-bottom: 0;
    }

    .main-navigation ul li p {
        font-size: 1em;
        color: #FFBE8D;
    }

    .main-navigation ul li a {
        font-size: 1em;
        color: #FFBE8D;
    }

    #siteLogo {
        background: url(/static/media/oie-logo_small.60eec4b4.svg) no-repeat left;
    }

    .menu-btn-wrapper {
        width: 60px;
    }

    header {
    }

    .logo {

    }

    .small-header {

    }

    #menu_btn {
        z-index: 10005;
        pointer-events: auto;
    }
}

@media print {
    .logo,
    #masthead,
    #menu_btn {
        display: none;
    }
}

/*************************************************
Menu hamburger begin
*************************************************/

.closed span, .closed span:after, .closed span:before {
    background-color: #FFF;
}

.opened span:after, .opened span:before {
    background-color: #FFF;
}

.c-hamburger {
    background: none;
    display: block;
    position: absolute;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 25px;
    height: 25px;
    font-size: 0;
    text-indent: -9999px;
    -webkit-appearance: none;
            appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
}

.c-hamburger:focus {
    outline: none;
}

.c-hamburger span {
    display: block;
    position: absolute;
    top: 11px;
    left: 0px;
    right: 0px;
    height: 3px;
    transition: 0.1s;
}

.c-hamburger span::before,
.c-hamburger span::after {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 3px;
    content: "";
    transition: 0.3s;
}

.c-hamburger span::before {
    top: -11px;
}

.c-hamburger span::after {
    bottom: -11px;
}

.c-hamburger--htx {
}

.c-hamburger--htx span {
    transition: background 0s 0.3s;
}

.c-hamburger--htx span::before,
.c-hamburger--htx span::after {
    transition-duration: 0.3s, 0.3s;
    transition-delay: 0.3s, 0s;
}

.c-hamburger--htx span::before {
    transition-property: top, -webkit-transform;
    transition-property: top, transform;
    transition-property: top, transform, -webkit-transform;
}

.c-hamburger--htx span::after {
    transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
    transition-property: bottom, transform, -webkit-transform;
}

/* active state, i.e. menu open */
.c-hamburger--htx.is-active {
}

.c-hamburger--htx.is-active span {
    -webkit-animation: center-span 0.3s;
}

.c-hamburger--htx.is-active span::before {
    top: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.c-hamburger--htx.is-active span::after {
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.c-hamburger--htx.is-active span::before,
.c-hamburger--htx.is-active span::after {
    transition-delay: 0s, 0.3s;
}

@-webkit-keyframes center-span {
  /* You could think of as "step 1" */
  0% {
    background: #6EDBD5;
  }
  /* You could think of as "step 2" */
  100% {
    background: none;
  }
}

/*


.c-hamburger--htx.is-active span::before {
    top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.c-hamburger--htx.is-active span::after {
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
*/

/*************************************************
Menu hamburger end
*************************************************/

.homepage .section,
.homepage .banner-wrapper,
.home-second-slide-content {
    min-height: 100vh;
}

.still-looking {
    display: flex;
    justify-content: center;
    align-items: center;
}

.still-looking img {
    width: 700px;
}

.round-btn {
    background: #FFF;
    border-radius: 50px;
    padding: 8px 15px;
    font-size: 1.125em;
    cursor: pointer;
}

.round-btn span {
    color: #000;
}

.round-btn-small {
    background: #FFF;
    border-radius: 50px;
    padding: 6px 12px;
    font-size: 1em;
    cursor: pointer;
    color: #000;
}

.projectInformationContainer {
    background: url(/static/media/oie-project_information-bg.73fe2789.svg) no-repeat;
}

.projectInformationPetrolContainer {
    background: url(/static/media/oie-project_information_petrol-bg.182d4f74.svg) no-repeat #000;
}

.projectInformationWrapper .projectInformation {
    background: #FFF;
    padding: 50px;
}

.projectInformationPetrolContainer .projectInformationWrapper .projectInformation {
    background: #000;
    padding: 50px;
}

.projectLinksWrapper .subtitle {
    margin-top: 50px;
    margin-bottom: 40px;
    font-size: 1.125em;
    font-weight: 400;
}

.projectLinksWrapper a:hover {
    text-decoration: none;
    color: #000;
}

.RoyaltiesStackbar rect {
    border-bottom: 1px solid #F00;
}

.source-wrapper {
    text-align: center;
    font-size: 0.875em;
}

.section-title {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 3em;
    font-weight: 200;
    /* margin-bottom: 50px; */
    /* display: inline-block; */
    /* float: left; */
}

.section-subtitle {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1.875em;
    font-weight: 500;
    margin-bottom: 50px;
}

.vis {
    margin: 0 auto;
}

.rv-xy-plot {
    position: relative;
}

text {
    fill: #FFF;
    /* font-size: 12px; */
}

#project line {
    /* stroke: #525656 !important; */
}

.project-module-header {
    display: flex;
    padding-top: 20px;
    height: 100px;
    align-items: center;
}

.project-module-select {
    float: left;
    padding: 12px 0;
    margin-left: 20px;
}

.hint {
    background: #333;
    color: #CCC;
    padding: 15px;
    border-radius: 5px;
}

.hint h5 {
    font-weight: 500;
    font-size: 1em;
}

.hint p {
    font-size: 0.875em;
}

.hint h5, .hint p {
    margin-bottom: 8px;
}

.hint p:last-child {
    margin-bottom: 0;
}

.download-report-btn {
    display: block;
    background: url(/static/media/oie-download_report.2b863519.svg) no-repeat center;
    width: 160px;
    height: 75px;
    background-size: 100%;
    cursor: pointer;
}

.download-report-btn span {
    display: none;
}

.link {
    display: block;
    margin-top: 30px;
    color: #FFF !important;
}

@media screen and (max-width: 600px) {
    .project-module-header {
        flex-direction: column;
        height: auto;
    }

    .project-module-select {
        margin-left: 0;
    }
}

@media print {
    .projectInformationPetrolContainer .projectInformationWrapper .projectInformation {
        background: #FFF;
    }

    .project-module-select {
        display: none;
    }

    .hide-on-print {
        display: none !important;
    }
}

.Timeline_h1__1RQLL {
   font-family: 'IBM Plex Sans', sans-serif;
   font-weight: 200;
   font-size: 3em;
}

.Timeline_h2__1Gqw- {
   font-family: 'IBM Plex Sans', sans-serif;
   font-weight: 500;
   font-size: 1.25em;
   color: #000;
   margin: 0 0 40px 0;
}

.Timeline_timelineEventsWrapper__1mVz6 {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 50px;
    margin-top: 150px;
}

@media screen and (max-width: 600px) {
    .Timeline_timelineEventsWrapper__1mVz6 {
        grid-template-columns: 100%;
        margin-top: 30px;
    }
}

.printables-logo {
    display: none;
}

.projectInformationWrapper {
    padding: 200px 0 100px 0;
}

.projectInformation h1 {
    font-size: 3.75em;
    font-weight: 300;
}

.projectInformation h2 {
    margin: 150px 0 80px 0;
}

.projectInformation h3 {
    font-weight: 700;
    font-size: 0.875em;
    color: #934E00;
}

.ambar {
    color: #934E00;
}

.petrolEarnings {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 50px;
}

.petrolEarnings h2 {
    font-size: 1.75em;
    color: #FFF;
}

.petrolEarnings p {
    font-size: 1.75em;
    color: #934E00;
}

.projectInformation p {
    font-weight: 300;
    font-size: 1.5em;
}

.projectInformationPetrolContainer .projectInformation h1,
.projectInformationPetrolContainer .projectInformation p {
    color: #FFF;
}

.subtitle-large {
    font-weight: 300;
}

.subtitle-medium {
    font-weight: 700;
}

.subtitle-small {
    font-weight: 700;
}

.content-large {
    font-size: 2.25em !important;
}

.content-medium {
    font-size: 2.125em;
}

.content-small {
    font-size: 1.5em;
}

.project-information-content {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row-gap: 20px;
}

.projectInformationEia .project-information-content {
    border-bottom: 1px solid #EEE;
    margin-bottom: 20px;
}

.projectInformationEia .project-information-content:first-child {
    border-bottom: transparent;
}

.projectInformationPrimary .project-information-subcontent {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row-gap: 20px;
}

@media screen and (max-width: 600px) {
    .petrolEarnings {
        grid-template-columns: 1fr;
    }

    .projectInformationWrapper {
        padding: 100px 0 50px 0;
    }

    .projectInformation h1 {
        font-size: 2.5em;
    }

    .projectInformation h2 {
        font-size: 1.5em;
        margin: 50px 0 50px 0;
    }

    .projectInformation p {
        font-weight: 300;
        font-size: 1.25em;
    }

    .content-large {
        font-size: 2em !important;
    }

    .content-medium {
        font-size: 1.5em;
    }

    .content-small {
        font-size: 1em;
    }

    .project-information-content {
        grid-template-columns: 100%;
    }
}

@media print {
    .printables-logo {
        display: block;
        height: 30px;
        margin-bottom: 20px;
    }

    .projectInformationWrapper {
        padding: 50px 0 0 0;
    }

    .projectInformation h2 {
        margin: 20px 0 30px 0;
    }

    .timeline-wrapper {
        display: none;
    }

    .timelinePrintables,
    .printables {
        display: grid;
    }

    .visxLegendTitle,
    .visx-legend-label,
    .petrolEarnings h2 {
        color: #000;
    }

    .projectInformationPetrolContainer {
        background: none;
    }

    .projectInformationPetrolContainer .projectInformation h1,
    .projectInformationPetrolContainer .projectInformation p {
        color: #000;
    }
}

.oro .st0 {
    fill: #D1FF55;
}

.cobre .st0 {
    fill: #A315FF;
}

.plomo .st0 {
    fill: #008E35;
}

.projectMineralsWrapper {
}

.projectMineralsHeader {
    margin-bottom: 120px;
}

.projectMineralsWrapper h1 {
    font-size: 1.875em;
    font-weight: 500;
    color: #FFF;
}

.projectMineralsWrapper h2 {
    font-weight: 400;
    font-size: 1em;
    color: #FFF;
}

.projectMineralsWrapper h3 {
    font-weight: 700;
    font-size: 0.875em;
    color: #934E00;
}

.projectMineralsWrapper p {
    font-weight: 300;
    font-size: 1em;
    margin-bottom: 0;
    color: #FFF;
}

.projectMineralsWrapper p span {
    margin-right: 10px;;
}

.project-minerals-content p span svg {
    height: 60px;
}

.subtitle-large {
    font-weight: 300;
}

.subtitle-medium {
    font-weight: 700;
}

.subtitle-small {
    font-weight: 700;
}

.content-large {
    font-size: 2.25em !important;
}

.content-medium {
    font-size: 2.125em;
}

.content-small {
    font-size: 1.5em;
}

.project-minerals-content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-row-gap: 20px;
}

/* .project-information-subcontent {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row-gap: 20px;
} */

@media screen and (max-width: 600px) {
    .projectMineralsWrapper {
        padding: 50px 0;
    }

    .projectMineralsWrapper h1 {
        font-size: 2.5em;
    }

    .projectMineralsWrapper h2 {
        font-size: 1.5em;
        margin: 50px 0 50px 0;
    }

    .projectMineralsWrapper p {
        font-weight: 300;
        font-size: 1.25em;
    }

    .content-large {
        font-size: 2em !important;
    }

    .content-medium {
        font-size: 1.5em;
    }

    .content-small {
        font-size: 1em;
    }

    .project-information-content {
        grid-template-columns: 100%;
    }
}

@media print {
    .projectMineralsWrapper p {
        color: #000;
    }

    .projectMineralsWrapper {
        padding: 50px 0 0 0;
    }

    .projectMineralsWrapper h2 {
        margin: 20px 0 30px 0;
    }

    .timeline-wrapper {
        display: none;
    }

    .printables {
        display: grid;
    }
}

.source-wrapper {

}

.source-text {
    color: white;
    padding: 15px;
    margin-bottom: 0;
    cursor: pointer;
}

.source-text span {
    font-size: 14px;
}

.sourceFeedback {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: center;
    z-index: 20000;
}

.sourceFeedbackInner {
    background: rgb(0, 188, 103);
    color: #FFF;
    padding: 5px;
    border-radius: 0 0 5px 5px;
}

.sourceFeedbackInner p {
    margin-bottom: 0;
}

.colorLegend {
  display: inline-grid;
  grid-template-columns: auto auto auto auto auto;
  overflow-x: none;
  grid-column-gap: 4%;

  float: none;
}

.colorLegend span {
  color: #FFF;
}

@media screen and (max-width: 600px) {

  .colorLegend {
      display: grid;
      grid-template-columns: 100%;
      grid-column-gap: 0;
      float: none;
  }
}

@media print {
    .colorLegend span {
        color: #000;
    }
}

.ExportationsMap_webmap__3g4Qm {
    height: calc(100vh - 80px);
}

.ExportationsMap_leaflet-container__2Qy8O {
    width: 100%;
}

.ExportationsMap_popup-title__2arM- {
    font-size: 1em;
}

.ExportationsMap_mapFiltersWrapper__1Dv7z {
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    max-width: 400px;
    position: absolute;
    padding: 40px;
    top: 0px;
    left: 0px;
    z-index: 1000;
    overflow-y: auto;
}

.ExportationsMap_tooltipButton__2MtsE {
    color: #000;
}

.ExportationsMap_mapFiltersWrapper__1Dv7z .ExportationsMap_title__3rZaU {
    font-size: 2em;
    max-width: none;
}

.ExportationsMap_sidebarHeadline__3uXZc {
    display: flex;
    align-items: center;
}

.ExportationsMap_projects-legends-list__pvUig .ExportationsMap_subtitle__2KFQ6,
.ExportationsMap_map-filters-wrapper__M-J5k .ExportationsMap_subtitle__2KFQ6 {
    font-weight: 200;
    margin-bottom: 20px;
}

.ExportationsMap_projects-legends-list__pvUig {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 120px;
    right: 40px;
    width: 240px;
    padding: 30px;
    z-index: 1000;
}

.ExportationsMap_projects-legends-list__pvUig img {
    height: 16px;
    margin-right: 8px;
}

.ExportationsMap_projects-legends-list__pvUig p:last-child {
    margin-bottom: 0;
}

.ExportationsMap_mapFilters__1BQ3A {
    display: flex;
    flex-direction: column;
}

.ExportationsMap_map-filter__-_4pD {
    width: 100%;
    margin-bottom: 20px;
}

.ExportationsMap_large-btn__1zbLS {
    display: inline-block;
    background: #DB6C00;
    width: 50%;
    max-width: 168px;
    padding: 6px 0;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #DB6C00;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1em;
    font-weight: 500;
    color: #FFF !important;
}

.ExportationsMap_open-filters-btn-wrapper__jHfam {
    display: none;
    width: 100%;
    position: absolute;
    z-index: 10000;
    bottom: 24px;
    text-align: center;
    padding: 0 20px;
}

.ExportationsMap_toggle-filters-btn__1MuG0 {
    display: none;
}

.ExportationsMap_projects-list__2Zv-C {
    width: 100%;
}

.ExportationsMap_projects-list-wrapper__3tg9i {
    height: 200px;
    overflow-y: auto;
}

.ExportationsMap_projects-list-wrapper__3tg9i a {
    color: #333;
}

.ExportationsMap_projects-list-wrapper__3tg9i a:hover {
    text-decoration: none;
    color: #B05900;
}

.ExportationsMap_infoSidebarToggle__3qfAV {
    position: absolute;
    width: 30px;
    background: #000;
}

.ExportationsMap_projectsLegendsList__3ba5r .ExportationsMap_subtitle__2KFQ6 {
    font-weight: 200;
    margin-bottom: 20px;
}

.ExportationsMap_projectsLegendsList__3ba5r {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 120px;
    right: 40px;
    width: 240px;
    padding: 30px;
    z-index: 1000;
}

.ExportationsMap_projectsLegendsList__3ba5r img {
    height: 16px;
    margin-right: 8px;
}

.ExportationsMap_projectsLegendsList__3ba5r p:last-child {
    margin-bottom: 0;
}

@media screen and (max-width: 600px) {
    .ExportationsMap_webmap__3g4Qm {
        height: 50vh;
    }

    .ExportationsMap_open-filters-btn-wrapper__jHfam {
        display: block;
    }

    .ExportationsMap_toggle-filters-btn__1MuG0 {
        display: inline-block;
    }

    .ExportationsMap_map-filters-wrapper__M-J5k {
        display: none;
        top: 0;
        left: 0;
        height: 100%;
        padding: 80px 20px;
    }

    .ExportationsMap_map-filters-wrapper__M-J5k .ExportationsMap_title__3rZaU {
        font-size: 1.5em;
    }

    .ExportationsMap_projects-list-wrapper__3tg9i {
        height: 155px;
        border-bottom: 20px solid rgba(0,0,0,0);
    }

    .ExportationsMap_projects-legends-list__pvUig {
        width: 180px;
        padding: 20px;
    }

    .ExportationsMap_projects-legends-list__pvUig p {
        font-size: 0.75em;
    }
}

.ProjectsMap_webmap__2wksD {
    height: calc(100vh - 80px);
}

.ProjectsMap_leaflet-container__HAHqT {
    width: 100%;
}

.ProjectsMap_popup-title__1KujX {
    font-size: 1em;
}

.ProjectsMap_mapFiltersWrapper__2AL53 {
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    max-width: 400px;
    position: absolute;
    padding: 40px;
    top: 0px;
    left: 0px;
    z-index: 1000;
    overflow-y: auto;
}

.ProjectsMap_tooltipButton__1zpvq {
    color: #000;
}

.ProjectsMap_mapFiltersWrapper__2AL53 .ProjectsMap_title__3UbYg {
    font-size: 2em;
    max-width: none;
}

.ProjectsMap_sidebarHeadline__2ytV4 {
    display: flex;
    align-items: center;
}

.ProjectsMap_projects-legends-list__3oLDH .ProjectsMap_subtitle__bwZFq,
.ProjectsMap_map-filters-wrapper__1w_Mv .ProjectsMap_subtitle__bwZFq {
    font-weight: 200;
    margin-bottom: 20px;
}

.ProjectsMap_projects-legends-list__3oLDH {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 120px;
    right: 40px;
    width: 240px;
    padding: 30px;
    z-index: 1000;
}

.ProjectsMap_projects-legends-list__3oLDH img {
    height: 16px;
    margin-right: 8px;
}

.ProjectsMap_projects-legends-list__3oLDH p:last-child {
    margin-bottom: 0;
}

.ProjectsMap_mapFilters__19Euy {
    display: flex;
    flex-direction: column;
}

.ProjectsMap_map-filter__1To-G {
    width: 100%;
    margin-bottom: 20px;
}

.ProjectsMap_large-btn__1fW61 {
    display: inline-block;
    background: #DB6C00;
    width: 50%;
    max-width: 168px;
    padding: 6px 0;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #DB6C00;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1em;
    font-weight: 500;
    color: #FFF !important;
}

.ProjectsMap_open-filters-btn-wrapper__1O1eI {
    display: none;
    width: 100%;
    position: absolute;
    z-index: 10000;
    bottom: 24px;
    text-align: center;
    padding: 0 20px;
}

.ProjectsMap_toggle-filters-btn__5MY-K {
    display: none;
}

.ProjectsMap_projects-list__1RfxA {
    width: 100%;
}

.ProjectsMap_projects-list-wrapper__1-5a_ {
    height: 200px;
    overflow-y: auto;
}

.ProjectsMap_projects-list-wrapper__1-5a_ a {
    color: #333;
}

.ProjectsMap_projects-list-wrapper__1-5a_ a:hover {
    text-decoration: none;
    color: #B05900;
}

.ProjectsMap_infoSidebarToggle__3E8xC {
    position: absolute;
    width: 30px;
    background: #000;
}

.ProjectsMap_projectsLegendsList__GHqz6 .ProjectsMap_subtitle__bwZFq {
    font-weight: 200;
    margin-bottom: 20px;
}

.ProjectsMap_projectsLegendsList__GHqz6 {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 120px;
    right: 40px;
    width: 240px;
    padding: 30px;
    z-index: 1000;
}

.ProjectsMap_projectsLegendsList__GHqz6 img {
    height: 16px;
    margin-right: 8px;
}

.ProjectsMap_projectsLegendsList__GHqz6 p:last-child {
    margin-bottom: 0;
}

@media screen and (max-width: 600px) {
    .ProjectsMap_webmap__2wksD {
        height: 50vh;
    }

    .ProjectsMap_open-filters-btn-wrapper__1O1eI {
        display: block;
    }

    .ProjectsMap_toggle-filters-btn__5MY-K {
        display: inline-block;
    }

    .ProjectsMap_map-filters-wrapper__1w_Mv {
        display: none;
        top: 0;
        left: 0;
        height: 100%;
        padding: 80px 20px;
    }

    .ProjectsMap_map-filters-wrapper__1w_Mv .ProjectsMap_title__3UbYg {
        font-size: 1.5em;
    }

    .ProjectsMap_projects-list-wrapper__1-5a_ {
        height: 155px;
        border-bottom: 20px solid rgba(0,0,0,0);
    }

    .ProjectsMap_projects-legends-list__3oLDH {
        width: 180px;
        padding: 20px;
    }

    .ProjectsMap_projects-legends-list__3oLDH p {
        font-size: 0.75em;
    }
}

.leaflet-container {
    width: 100%;
}

.popup-title {
    font-size: 1em;
}

.map-filters-wrapper {
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: calc(100% - 80px);
    max-width: 400px;
    position: absolute;
    padding: 40px;
    top: 80px;
    left: 0px;
    z-index: 10000;
    overflow-y: auto;
}

.map-filters-wrapper .title {
    font-size: 2em;
    max-width: none;
}

.projects-legends-list .subtitle,
.map-filters-wrapper .subtitle {
    font-weight: 200;
    margin-bottom: 20px;
}

.projects-legends-list {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 120px;
    right: 40px;
    width: 240px;
    padding: 30px;
    z-index: 1000;
}

.projects-legends-list img {
    height: 16px;
    margin-right: 8px;
}

.projects-legends-list p:last-child {
    margin-bottom: 0;
}

.map-filters {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.map-filter {
    width: 100%;
    margin-bottom: 20px;
}

.large-btn {
    display: inline-block;
    background: #DB6C00;
    width: 50%;
    max-width: 168px;
    padding: 6px 0;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #DB6C00;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1em;
    font-weight: 500;
    color: #FFF !important;
}

.open-filters-btn-wrapper {
    display: none;
    width: 100%;
    position: absolute;
    z-index: 10000;
    bottom: 24px;
    text-align: center;
    padding: 0 20px;
}

.toggle-filters-btn {
    display: none;
}

.projects-list {
    width: 100%;
}

.projects-list-wrapper {
    height: 200px;
    overflow-y: auto;
}

.projects-list-wrapper a {
    color: #333;
}

.projects-list-wrapper a:hover {
    text-decoration: none;
    color: #B05900;
}

@media screen and (max-width: 600px) {
    .open-filters-btn-wrapper {
        display: block;
    }

    .toggle-filters-btn {
        display: inline-block;
    }

    .map-filters-wrapper {
        display: none;
        top: 0;
        left: 0;
        height: 100%;
        padding: 80px 20px;
    }

    .map-filters-wrapper .title {
        font-size: 1.5em;
    }

    .projects-list-wrapper {
        height: 155px;
        border-bottom: 20px solid rgba(0,0,0,0);
    }

    .projects-legends-list {
        width: 180px;
        padding: 20px;
    }

    .projects-legends-list p {
        font-size: 0.75em;
    }
}

.extractionsTreemapHeader {
    width: 100%;
    height: 100px;
    float: left;
}

.treemap {
    float: left;
}

.treemapLegend {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-column-gap: 4%;
    float: left;
}

.treemapLegend span {
    color: #FFF;
}

@media screen and (max-width: 600px) {
    .extractionsTreemapHeader {
        height: 150px;
        float: none;
    }

    .treemap {
        float: none;
    }

    .treemapLegend {
        display: grid;
        grid-template-columns: 100%;
        grid-column-gap: 0;
        float: none;
    }
}

.GovernanceTimeline_h1__1X35f {
   font-family: 'IBM Plex Sans', sans-serif;
   font-weight: 200;
   font-size: 3em;
   Color: #FFF;
}

.GovernanceTimeline_h2__3cXGW {
   font-family: 'IBM Plex Sans', sans-serif;
   font-weight: 500;
   font-size: 1.25em;
   color: #FFF;
   margin: 0 0 40px 0;
}

.GovernanceTimeline_timelineEventsWrapper__1N3gC {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 50px;
    margin-top: 150px;
}

.GovernanceTimeline_timelineWrapper__36gFA ul li {
    background-image: none !important;
}

@media screen and (max-width: 600px) {
    .GovernanceTimeline_timelineEventsWrapper__1N3gC {
        grid-template-columns: 100%;
        margin-top: 30px;
    }
}

.governanceDisclaimer {
    padding: 80px 180px 0 180px;
}

.governanceDisclaimerWrapper {
    -webkit-column-count: 2;
            column-count: 2;
    grid-column-gap: 100px;
    -webkit-column-gap: 100px;
            column-gap: 100px;
    color: #FFF;
}

.governanceTimeline {
    color: #FFF;
}

@media screen and (max-width: 600px) {
    .governanceDisclaimer {
        padding: 50px 20px 0 20px;
    }

    .governanceDisclaimer p {
        -webkit-column-count: 1;
                column-count: 1;
        grid-column-gap: 0;
        -webkit-column-gap: 0;
                column-gap: 0;
    }
}

#seg {
    background: url(/static/media/oie-seg-bg.8b33ee02.svg) no-repeat;
    background-size: 100%;
    background-position: top center;
}

#seg .title {
    width: 100%;
    max-width: none;
}

#seg .paragraph {
    max-width: none;
}

#seg .banner-wrapper {
    padding-bottom: 80px;
}

#seg .banner-wrapper-content {
    flex-direction: row;
    padding: 200px 30px 0 180px;
}

#seg .banner-wrapper-content div {
    width: 50%;
}

#seg .banner-info {
    width: 60%;
    padding-right: 20px;
}

#seg .wrapper {
    width: 100%;
    padding: 0 80px 80px 180px;
}

#seg .wrapper-symmetrical {
    width: 100%;
    padding: 0 180px 80px 180px;
}

#seg .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
}

.cc-image {
    background: url(/static/media/oie-cc.5b29f60e.svg) no-repeat center;
    width: 40%;
    height: 0;
    /* padding-bottom: 40%; */
    padding-bottom: 50%;
}

.water-indicators h2 {
    height: 50px;
}

.water-indicators p {
    font-size: 0.875em;
}

.water-indicators img {
    margin-right: 10px;
}

.water-indicators > div {
    display: flex;
    align-items: flex-start;
}

.paddingTop {
    padding-top: 60px !important;
}

@media screen and (max-width: 600px) {
    #seg .banner-wrapper-content {
        padding: 100px 20px 0 20px;
        flex-direction: column-reverse;
    }

    .cc-image {
        padding-bottom: 100%;
    }

    #seg .banner-wrapper-content div {
        width: 100%;
    }

    #seg .grid {
        grid-template-columns: 1fr;
    }

    #seg .wrapper,
    #seg .wrapper-symmetrical {
        padding: 0 20px 80px;
    }

    .small-title {
        font-size: 1.5em;
    }
}

.AguaWebMapView_webmap__uLdS_ {
    height: calc(100vh - 80px);
}

.AguaWebMapView_leaflet-container__z2dza {
    width: 100%;
}

.AguaWebMapView_popup-title__19kSe {
    font-size: 1em;
}

.AguaWebMapView_mapFiltersWrapper__13h1r {
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    max-width: 400px;
    position: absolute;
    padding: 40px;
    top: 0px;
    left: 0px;
    z-index: 1000;
    overflow-y: auto;
}

.AguaWebMapView_tooltipButton__1JJiE {
    color: #000;
}

.AguaWebMapView_mapFiltersWrapper__13h1r .AguaWebMapView_title__3d1kC {
    font-size: 2em;
    max-width: none;
}

.AguaWebMapView_sidebarHeadline__1--JO {
    display: flex;
    align-items: center;
}

.AguaWebMapView_projects-legends-list__1i0Oi .AguaWebMapView_subtitle__38m9M,
.AguaWebMapView_map-filters-wrapper__1YRJG .AguaWebMapView_subtitle__38m9M {
    font-weight: 200;
    margin-bottom: 20px;
}

.AguaWebMapView_projects-legends-list__1i0Oi {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 120px;
    right: 40px;
    width: 240px;
    padding: 30px;
    z-index: 1000;
}

.AguaWebMapView_projects-legends-list__1i0Oi img {
    height: 16px;
    margin-right: 8px;
}

.AguaWebMapView_projects-legends-list__1i0Oi p:last-child {
    margin-bottom: 0;
}

.AguaWebMapView_mapFilters__1Rqkt {
    display: flex;
    flex-direction: column;
}

.AguaWebMapView_map-filter__2rZ7S {
    width: 100%;
    margin-bottom: 20px;
}

.AguaWebMapView_large-btn__1GR-Z {
    display: inline-block;
    background: #DB6C00;
    width: 50%;
    max-width: 168px;
    padding: 6px 0;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #DB6C00;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1em;
    font-weight: 500;
    color: #FFF !important;
}

.AguaWebMapView_open-filters-btn-wrapper__317Fa {
    display: none;
    width: 100%;
    position: absolute;
    z-index: 10000;
    bottom: 24px;
    text-align: center;
    padding: 0 20px;
}

.AguaWebMapView_toggle-filters-btn__3ZSWg {
    display: none;
}

.AguaWebMapView_projects-list__2FdXa {
    width: 100%;
}

.AguaWebMapView_projects-list-wrapper__v9PYl {
    height: 200px;
    overflow-y: auto;
}

.AguaWebMapView_projects-list-wrapper__v9PYl a {
    color: #333;
}

.AguaWebMapView_projects-list-wrapper__v9PYl a:hover {
    text-decoration: none;
    color: #B05900;
}

.AguaWebMapView_infoSidebarToggle__1Kk5P {
    position: absolute;
    width: 30px;
    background: #000;
}

.AguaWebMapView_projectsLegendsList__1fah8 .AguaWebMapView_subtitle__38m9M {
    font-weight: 200;
    margin-bottom: 20px;
}

.AguaWebMapView_projectsLegendsList__1fah8 {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 120px;
    right: 40px;
    width: 240px;
    padding: 30px;
    z-index: 1000;
}

.AguaWebMapView_projectsLegendsList__1fah8 img {
    height: 16px;
    margin-right: 8px;
}

.AguaWebMapView_projectsLegendsList__1fah8 p:last-child {
    margin-bottom: 0;
}

@media screen and (max-width: 600px) {
    .AguaWebMapView_webmap__uLdS_ {
        height: 50vh;
    }

    .AguaWebMapView_open-filters-btn-wrapper__317Fa {
        display: block;
    }

    .AguaWebMapView_toggle-filters-btn__3ZSWg {
        display: inline-block;
    }

    .AguaWebMapView_map-filters-wrapper__1YRJG {
        display: none;
        top: 0;
        left: 0;
        height: 100%;
        padding: 80px 20px;
    }

    .AguaWebMapView_map-filters-wrapper__1YRJG .AguaWebMapView_title__3d1kC {
        font-size: 1.5em;
    }

    .AguaWebMapView_projects-list-wrapper__v9PYl {
        height: 155px;
        border-bottom: 20px solid rgba(0,0,0,0);
    }

    .AguaWebMapView_projects-legends-list__1i0Oi {
        width: 180px;
        padding: 20px;
    }

    .AguaWebMapView_projects-legends-list__1i0Oi p {
        font-size: 0.75em;
    }
}

.gc-image {
    background: url(/static/media/oie-gc.15158d10.svg) no-repeat center;
    width: 40%;
    height: 0;
    /* padding-bottom: 40%; */
    padding-bottom: 50%;
}

#corporativeGovernance {
    /* background: url('corporativeGovernance-bg.svg') no-repeat; */
    background-size: 100%;
    background-position: top center;
}

#stockViewer p {
    margin: 0;
    margin-bottom: 0 !important;
}

#SEC a {
    color: #2eff8b;
    text-decoration: underline;
}

#SEC a:hover {
    color: #FFF;
}

.ocre {
    background-color: #964d18;
    color: #FFF;
}

.ocre .title {
    color: #fda639 !important;
}

.green {
    background-color: #003c3c;
    color: #FFF;
}

.green .section-title,
.green h3 {
    color: #2eff8b !important;
}

.mapa-actores {
    width: 100%;
}

#corporativeGovernance .title {
    width: 100%;
    max-width: none;
}

#corporativeGovernance .paragraph {
    max-width: none;
}

#corporativeGovernance .banner-wrapper {
    padding-bottom: 80px;
}

#corporativeGovernance .banner-wrapper-content {
    flex-direction: row;
    padding: 200px 30px 0 180px;
}

#corporativeGovernance .banner-wrapper-content-full {
    display: flex;
    flex-direction: row;
}

#corporativeGovernance .banner-wrapper-content div {
    width: 50%;
}

#corporativeGovernance .banner-wrapper-content-full div {
    width: 50%;
}

#corporativeGovernance .banner-info {
    width: 60%;
    padding-right: 20px;
}

#corporativeGovernance .wrapper {
}

#corporativeGovernance .wrapper-symmetrical {
    width: 100%;
    padding: 0 180px 80px 180px;
}

#corporativeGovernance .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
}

#corporativeGovernance .section div {
}

.gc-image {
    width: 40%;
    height: 0;
    /* padding-bottom: 40%; */
    padding-bottom: 50%;
}

.paddingTop {
    padding-top: 60px !important;
}

@media screen and (max-width: 600px) {
    #corporativeGovernance .banner-wrapper-content {
        padding: 100px 20px 0 20px;
        flex-direction: column-reverse;
    }

    .gc-image {
        padding-bottom: 100%;
    }

    #corporativeGovernance .banner-wrapper-content div {
        width: 100%;
    }

    #corporativeGovernance .grid {
        grid-template-columns: 1fr;
    }

    #corporativeGovernance .wrapper,
    #corporativeGovernance .wrapper-symmetrical {
        padding: 20px;
    }

    #corporativeGovernance .banner-wrapper-content-full {
        flex-direction: column;
    }

    #corporativeGovernance .banner-wrapper-content-full div {
        width: 100%;
    }

    .small-title {
        font-size: 1.5em;
    }
}


.printables-logo {
    display: none;
}

.projectInformationWrapper {
    padding: 200px 0 100px 0;
}

.projectInformation h1 {
    font-size: 3.75em;
    font-weight: 300;
}

.projectInformation h2 {
    margin: 150px 0 80px 0;
}

.projectInformation h3 {
    font-weight: 700;
    font-size: 0.875em;
    color: #934E00;
}

.projectInformation p {
    font-weight: 300;
    font-size: 1.5em;
}

.subtitle-large {
    font-weight: 300;
}

.subtitle-medium {
    font-weight: 700;
}

.subtitle-small {
    font-weight: 700;
}

.content-large {
    font-size: 2.25em !important;
}

.content-medium {
    font-size: 2.125em;
}

.content-small {
    font-size: 1.5em;
}

.project-information-content {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row-gap: 20px;
}

/* .project-information-subcontent {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row-gap: 20px;
} */

@media screen and (max-width: 600px) {
    .projectInformationWrapper {
        padding: 100px 0 50px 0;
    }

    .projectInformation h1 {
        font-size: 2.5em;
    }

    .projectInformation h2 {
        font-size: 1.5em;
        margin: 50px 0 50px 0;
    }

    .projectInformation p {
        font-weight: 300;
        font-size: 1.25em;
    }

    .content-large {
        font-size: 2em !important;
    }

    .content-medium {
        font-size: 1.5em;
    }

    .content-small {
        font-size: 1em;
    }

    .project-information-content {
        grid-template-columns: 100%;
    }
}

@media print {
    .printables-logo {
        display: block;
        height: 30px;
        margin-bottom: 20px;
    }

    .projectInformationWrapper {
        padding: 50px 0 0 0;
    }

    .projectInformation h2 {
        margin: 20px 0 30px 0;
    }

    .timeline-wrapper {
        display: none;
    }

    .timelinePrintables,
    .printables {
        display: grid;
    }
}

.still-looking {
    display: flex;
    justify-content: center;
    align-items: center;
}

.still-looking img {
    width: 700px;
}

/*
.round-btn {
    background: #FFF;
    border-radius: 50px;
    padding: 8px 15px;
    font-size: 1.125em;
    cursor: pointer;
}

.round-btn-small {
    background: #FFF;
    border-radius: 50px;
    padding: 6px 12px;
    font-size: 1em;
    cursor: pointer;
    color: #000;
}
*/

.projectInformationWrapper .projectInformation {
    background: #FFF;
    padding: 50px;
}

.projectInformationContainer {
    background: url(/static/media/oie-project_information-bg.73fe2789.svg) no-repeat;
}

.projectLinksWrapper .subtitle {
    margin-top: 50px;
    margin-bottom: 40px;
    font-size: 1.125em;
    font-weight: 400;
}

.projectLinksWrapper a:hover {
    text-decoration: none;
    color: #000;
}

.RoyaltiesStackbar rect {
    border-bottom: 1px solid #F00;
}

.source-wrapper {
    text-align: center;
    font-size: 0.875em;
}

.section-title {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 3em;
    font-weight: 200;
    /* margin-bottom: 50px; */
    /* display: inline-block; */
    /* float: left; */
}

.section-subtitle {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1.875em;
    font-weight: 500;
    margin-bottom: 50px;
}

.vis {
    margin: 0 auto;
}

.rv-xy-plot {
    position: relative;
}

text {
    fill: #FFF;
    /* font-size: 12px; */
}

.project-module-header {
    display: flex;
    padding-top: 20px;
    height: 100px;
    align-items: center;
    margin-bottom: 30px;
}

.project-module-select {
    float: left;
    padding: 12px 0;
    margin-left: 20px;
}

.hint {
    background: #333;
    color: #CCC;
    padding: 15px;
    border-radius: 5px;
}

.hint h5 {
    font-weight: 500;
    font-size: 1em;
}

.hint p {
    font-size: 0.875em;
}

.hint h5, .hint p {
    margin-bottom: 8px;
}

.hint p:last-child {
    margin-bottom: 0;
}

.download-report-btn {
    display: block;
    background: url(/static/media/oie-download_report.2b863519.svg) no-repeat center;
    width: 160px;
    height: 75px;
    background-size: 100%;
    cursor: pointer;
}

.download-report-btn span {
    display: none;
}

.link {
    display: block;
    margin-top: 30px;
    color: #FFF !important;
}

@media screen and (max-width: 600px) {
    .project-module-header {
        flex-direction: column;
        height: auto;
        align-items: flex-start;
    }

    .project-module-select {
        margin-left: 0;
    }
}

@media print {
    .project-module-select {
        display: none;
    }

    .hide-on-print {
        display: none !important;
    }
}

.graphWrapper {
    position: relative;
}

.graphContainer {
    background: #000;
}

.graphHeaderContainer {
    display: none;
}

.visx-annotation-connector {
    stroke-width: 2px;
}

.graphHeader {
    display: none;
    background: #FFF;
    padding: 20px;
}

.graphTitle {
    color: #000;
    padding-left: 20px;
}

#cm {
    background: rgb(165,127,255);
    background: linear-gradient(180deg, rgba(165,127,255,1) 0%, rgba(186,219,255,1) 60%, rgba(255,255,255,0) 100%);
    background-size: 100% 500px;
    background-repeat: no-repeat;
}

#cm .title {
    /* width: 50%; */
    max-width: none;
}

#cm .paragraph {
    max-width: none;
}

#cm .banner-wrapper {
    background: url(/static/media/oie-cm.f46ec170.svg) no-repeat;
    background-size: contain;
    background-position: center 100px;
    padding-bottom: 80px;
}

#cm .banner-wrapper-content {
    padding: 200px 180px 0 180px;
}

#cm .banner-wrapper-content div {
    /* width: 100%; */
}

#cm .banner-info {
    width: 60%;
    padding-right: 20px;
}

#cm .wrapper {
    width: 100%;
}

#cm .wrapper-symmetrical {
    width: 100%;
    padding: 0 180px 80px 180px;
}

#cm .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
}

#cm .twoColumns {
    align-items: center;
}

.cmLegends img {
    width: 20px;
}

.cmDisclaimerWrapper {
    margin-top: 180px;
    /* column-count: 2; */
    grid-column-gap: 100px;
    -webkit-column-gap: 100px;
            column-gap: 100px;
}

.water-indicators h2 {
    height: 50px;
}

.water-indicators p {
    font-size: 0.875em;
}

.water-indicators img {
    margin-right: 10px;
}

.water-indicators > div {
    display: flex;
    align-items: flex-start;
}

.paddingTop {
    padding-top: 60px !important;
}


.cmWebmap {
    height: calc(100vh - 80px);
}

.cmWebmapWrapper {
    background: #C1BBFF;
    padding: 80px 100px;
}

.cmWebmapWrapper .section-title {
    margin-bottom: 50px;
}

.cmWebmapWrapperContent {
    display: grid;
    grid-template-columns: 30% 70%;
}

@media screen and (max-width: 600px) {
    #cm .title {
        font-size: 1.5em;
        width: 60%;
    }

    .cmDisclaimerWrapper {
        margin-top: 0;
    }

    #cm .banner-wrapper-content {
        padding: 100px 20px 0 20px;
        flex-direction: column-reverse;
    }

    .cc-image {
        padding-bottom: 100%;
    }

    #cm .banner-wrapper-content div {
        width: 100%;
    }

    #cm .grid {
        grid-template-columns: 1fr;
    }

    #cm .wrapper,
    #cm .wrapper-symmetrical {
        display: block;
        padding: 0 20px 80px;
    }

    .cmWebmapWrapper {
        padding: 80px 20px;
    }

    .cmWebmapWrapperContent {
        display: block;
    }

    .small-title {
        font-size: 1.5em;
    }
}

a {
    color: #000;
}

a:hover {
    color: #000;
}

.sidebar {
    padding: 60px;
}

.sidebar h1 {
    font-size: 16px;
    text-transform: uppercase;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar ul li {
    font-size: 16px;
    margin-bottom: 10px;
}

.catalogue-content {
    width: 60%;
}

.catalogue-content h2 {
    font-weight: 300;
    font-size: 14px;
}

.catalogue-content input {
    width: 100%;
    padding: 8px;
}

.tags {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin-top: 20px;
}

.tags li {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 300;
    padding: 3px;
    background: #EEE;
}

.catalogue-entries {
    padding-top: 40px;
}

.catalogue-entry {
    padding: 20px;
    border-top: 1px solid #333;
}

.catalogue-entry h1 {
    font-size: 24px;
    font-weight: 400;
}

.catalogue-entry p {
    max-width: 70%;
    font-size: 12px;
}

#catalogue {
    display: flex;
    padding: 160px 100px 100px 100px;
}

#catalogue .title {
    width: 100%;
    max-width: none;
}

#catalogue .paragraph {
    max-width: none;
}

#catalogue .banner-wrapper {
    padding-bottom: 80px;
}

#catalogue .banner-wrapper-content {
    flex-direction: row;
    padding: 200px 30px 0 180px;
}

#catalogue .banner-wrapper-content div {
    width: 50%;
}

#catalogue .banner-info {
    width: 60%;
    padding-right: 20px;
}

#catalogue .wrapper {
    width: 100%;
    padding: 0 80px 80px 180px;
}

#catalogue .wrapper-symmetrical {
    width: 100%;
    padding: 0 180px 80px 180px;
}

#catalogue .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
}

.cc-image {
    background: url(/static/media/oie-cc.5b29f60e.svg) no-repeat center;
    width: 40%;
    height: 0;
    /* padding-bottom: 40%; */
    padding-bottom: 50%;
}

.water-indicators h2 {
    height: 50px;
}

.water-indicators p {
    font-size: 0.875em;
}

.water-indicators img {
    margin-right: 10px;
}

.water-indicators > div {
    display: flex;
    align-items: flex-start;
}

.paddingTop {
    padding-top: 60px !important;
}

@media screen and (max-width: 600px) {
    #catalogue .banner-wrapper-content {
        padding: 100px 20px 0 20px;
        flex-direction: column-reverse;
    }

    .cc-image {
        padding-bottom: 100%;
    }

    #catalogue .banner-wrapper-content div {
        width: 100%;
    }

    #catalogue .grid {
        grid-template-columns: 1fr;
    }

    #catalogue .wrapper,
    #catalogue .wrapper-symmetrical {
        padding: 0 20px 80px;
    }

    .small-title {
        font-size: 1.5em;
    }
}

.pagination {
    justify-content: center;
    margin-top: 30px;
}

.page-link {
    cursor: pointer;
}

.page-item.active .page-link {
    background-color: #000;
    border-color: #000;
}

.sidebar {
    padding: 60px;
}

.sidebar h1 {
    font-size: 16px;
    text-transform: uppercase;
}

.sidebar ul {
    list-style-type: none;
}

.sidebar ul li {
    font-size: 16px;
    margin-bottom: 10px;
}

.libraryContent {
    /* width: 80%; */
}

.libraryContent h2 {
    font-weight: 300;
    font-size: 14px;
}

.libraryContent input {
    width: 100%;
    padding: 8px;
}

.tags {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin-top: 20px;
}

.tags li {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 300;
    padding: 3px;
    background: #EEE;
}

.library-entries {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.map-entries {
    display: block;
}

.library-entry {
    padding: 20px;
    text-align: center;
}

.library-entry a {
    text-decoration: none;
}

.library-entry h1 {
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 5px 0;
    color: #000;
}

.library-entry h2 {
    font-size: 12px;
    font-weight: 300;
    margin: 0 0 5px 0;
    color: #000;
}

.library-entry h3 {
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 5px 0;
    color: #000;
}

.library-entry p {
    font-size: 8px;
    color: #CCC;
    font-weight: 300;
    margin: 0;
}

.library-entry p strong {
    color: #000;
}

.libraryEntryImage {
    width: 100%;
    background: #EEE;
    margin-bottom: 10px;
}



.mapEntryImage {
    width: 100%;
}

.libraryNav ul {
    display: flex;
    list-style-type: none;
    padding: 0;
}

.libraryNav ul li {
    border: 1px solid #000;
}

.libraryNav ul li:first-child {
    border-radius: 5px 0 0 5px;
}

.libraryNav ul li:last-child {
    border-radius: 0 5px 5px 0;
    border-left: 0;
}

.libraryNav ul li a {
    display: block;
    width: 120px;
    padding: 5px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
}

.libraryNav ul li.current a,
.libraryNav ul li a:hover {
    background: #000;
    color: #FFF;
    text-decoration: none;
}

#libraryFeatured {
    display: flex;
    background: rgb(204,204,204);
    background: linear-gradient(0deg, rgba(204,204,204,1) 0%, rgba(255,255,255,1) 100%);
    height: 250px;
    margin: 0px 0;
}

#libraryFeaturedContent {
    width: 100%;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

#libraryFeaturedContent h1 {
    font-size: 22px;
    font-weight: 400;
}

#libraryFeaturedContent p {
    font-size: 10px;
    margin: 0;
}

#libraryFeaturedContent a {
    font-size: 12px;
    color: #f26a39;
}

.libraryBannerImage {
    width: 100%;
    max-height: 150x;
    max-width: 1250px;
    object-fit: contain;
    grid-column: span 2
}

.libraryBannerImageA {
    width: 100%;
    max-height: 150px;
    grid-column: span 2;
    text-align: center;
}


#library {
    display: flex;
    padding: 160px 100px 100px 100px;
}

@media screen and (max-width: 600px) {
    #library {
        display: flex;
        padding: 100px 30px;
    }

    #libraryFeatured {
        height: auto;
    }

    #libraryFeaturedContent {
        width: 100%;
        padding: 30px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px;
    }

    .library-entries {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
    }

    .page-link {
        font-size: 11px;
    }

    .MuiMasonry-root {
        margin: 0 !important;
    }
}

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
    background: #FFF;
  color: #444;
  cursor: pointer;
  padding: 6px 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

.chevron-circle {
    fill: none;
}

.chevron-pointer {
    stroke: #009B84;
}

.active-accordion .chevron-circle {
    fill: #009B84;
}

.active-accordion .chevron-pointer {
    stroke: #FFF;
}

/* Style the accordion content title */
.accordion__title {
  font-weight: 200;
  font-size: 2em;
  color: #009883;
  margin-left: 20px;
  margin-bottom: 0;
}

/* Style the accordion chevron icon */
.accordion__icon {
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
    background-color: white;
    overflow: hidden;
    transition: max-height 0.6s ease;
    padding-left: 30%;
}

/* Style the accordion content text */
.accordion__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}

@media screen and (max-width: 600px) {
    .accordion__title {
        font-size: 0.875em;
        font-weight: 400;
    }
}

.aboutUs .section,
.aboutUs .aboutus-banner {
    min-height: 100vh;
}

.aboutus-banner {
    background: linear-gradient(180deg, rgba(168,113,51,1) 0%, rgba(230,118,0,1) 100%);
    padding-top: 100px;
}

.aboutus-banner-content {
    background: url(/static/media/oie-aboutus-banner-bg.b9ba9556.svg) no-repeat right;
    background-size: 80%;
}

.accordions-wrapper {
    padding-top: 80px;
}

table {
    width: 100%;
}

th {
    vertical-align: top;
    text-align: center;
}

th, td {
    padding: 5px;
    vertical-align: top;
}

ul {
    padding: 10px 0 0 20px;
}

/* @import url('https://fonts.googleapis.com/icon?family=Material+Icons'); */

.material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}

@font-face {
  font-family: 'oie-icons';
  src:  url(/static/media/oie-icons.beacb4f1.eot);
  src:  url(/static/media/oie-icons.beacb4f1.eot#iefix) format('embedded-opentype'),
    url(/static/media/oie-icons.dae99954.ttf) format('truetype'),
    url(/static/media/oie-icons.828a279c.woff) format('woff'),
    url(/static/media/oie-icons.d138bef9.svg#oie-icons) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.graphHeaderContainer {
    display: none;
}

.graphHeader {
    display: none;
    background: #FFF;
    padding: 20px;
}

.projectModuleHeaderTitleTooltip {
    display: flex;
}

.downloadBtn {
    display: flex;
    justify-content: space-between;
    width: 170px;
}

.downloadBtn span {
    margin-left: 5px;
}

.graphToImgBtn {
    cursor: pointer;
    padding: 8px;
    margin-top: 16px;
    /* display: inline-block; */
    background: #007bff;
    border-radius: 5px;
    float: right;
    color: #FFF;
}

.graphToImgBtn:hover {
    background: #489bf4;
    text-decoration: none;
}

.csvBtn {
    cursor: pointer;
    padding: 8px;
    margin-top: 16px;
    margin-right: 8px;
    /* display: inline-block; */
    background: #0fc168;
    border-radius: 5px;
    float: right;
    color: #FFF;
}

.csvBtn:hover {
    background: #0cdb74;
    color: #FFF;
    text-decoration: none;
}

@media screen and (max-width: 600px) {
    .graphToImgBtn {
        display: none;
    }
}

.graphToImgBtn p {
    display: flex;
    align-items: center;
    margin: 0;
}

.graphToImgBtn p span {
    margin-left: 5px;
}

.rv-xy-plot__grid-lines__line {
    stroke: #666 !important;
}

.visx-legend-item {
    margin: 5px !important;
}

.visx-legend-item rect {
    stroke: #666 !important;
}

.visxTooltip strong {
    text-transform: uppercase;
}

.visxLegend {
  line-height: 0.9em;
  color: #efefef;
  font-size: 10px;
  font-family: arial;
  padding: 15px;
  border-radius: 8px;
}

.visxLegend > div {
    flex-wrap: wrap;
}

.visxLegendTitle {
    font-size: 1.5em;
    padding-bottom: 20px;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'oie-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twitter:before {
  content: "\ea96";
}

[class^="icon-"]:hover,
[class*=" icon-"]:hover {
    text-decoration: none;
    color: #FF6833;
}

[class^="icon-"] span, [class*=" icon-"] span {
    display: none;
}

* {
    box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.logoBig {
    width: 160px;
    height: 60px;
    background: url(/static/media/oie-logo_big.7f482c9a.svg) no-repeat center;
}

.logoBig span {
    display: none;
}

.footer {
    background: #000;
    width: 100%;
    padding: 40px 20px;
}

.footer div {
    padding: 0 10px;
}

.footerRow {
    display: flex;
    justify-content: space-between;
}

.footer nav ul {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.footer nav ul li {
    float: left;
    border-bottom: 2px solid #FF6833;
    margin: 0 5px;
    padding: 8px 0;
}

.footer nav ul li a {
    display: block;
    color: #FFF;
    width: 100%;
    height: 100%;
    font-weight: 500;
}

.footer nav ul li a:hover {
    text-decoration: none;
}

.footerText {
    font-weight: 500;
}

.redText {
    color: #FF6833;
}

.whiteText {
    color: #FFF;
}

.smallText {
    font-size: 12px;
}

.footer p a {
    color: #FFF;
    font-weight: 500;
}

#disclaimer {
    max-width: 800px;
}

#oxfam {
    width: 116px;
    height: 80px;
    background: url(/static/media/oxfam.6bb966a7.svg) no-repeat center;
}

.twoColumns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 100px;
}

hr {
    margin: 50px 180px;
}

@media screen and (max-width: 600px) {
    .footerRow {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .footer nav ul {
        display: block;
        padding: 20px 0;
    }

    .footer nav ul li {
        float: none;
        margin: 0;
    }
}

@media print {
    .footer {
        display: none;
    }
}

