.h1 {
   font-family: 'IBM Plex Sans', sans-serif;
   font-weight: 200;
   font-size: 3em;
}

.h2 {
   font-family: 'IBM Plex Sans', sans-serif;
   font-weight: 500;
   font-size: 1.25em;
   color: #000;
   margin: 0 0 40px 0;
}

.timelineEventsWrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 50px;
    margin-top: 150px;
}

@media screen and (max-width: 600px) {
    .timelineEventsWrapper {
        grid-template-columns: 100%;
        margin-top: 30px;
    }
}
