.printables-logo {
    display: none;
}

.projectInformationWrapper {
    padding: 200px 0 100px 0;
}

.projectInformation h1 {
    font-size: 3.75em;
    font-weight: 300;
}

.projectInformation h2 {
    margin: 150px 0 80px 0;
}

.projectInformation h3 {
    font-weight: 700;
    font-size: 0.875em;
    color: #934E00;
}

.ambar {
    color: #934E00;
}

.petrolEarnings {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 50px;
}

.petrolEarnings h2 {
    font-size: 1.75em;
    color: #FFF;
}

.petrolEarnings p {
    font-size: 1.75em;
    color: #934E00;
}

.projectInformation p {
    font-weight: 300;
    font-size: 1.5em;
}

.projectInformationPetrolContainer .projectInformation h1,
.projectInformationPetrolContainer .projectInformation p {
    color: #FFF;
}

.subtitle-large {
    font-weight: 300;
}

.subtitle-medium {
    font-weight: 700;
}

.subtitle-small {
    font-weight: 700;
}

.content-large {
    font-size: 2.25em !important;
}

.content-medium {
    font-size: 2.125em;
}

.content-small {
    font-size: 1.5em;
}

.project-information-content {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row-gap: 20px;
}

.projectInformationEia .project-information-content {
    border-bottom: 1px solid #EEE;
    margin-bottom: 20px;
}

.projectInformationEia .project-information-content:first-child {
    border-bottom: transparent;
}

.projectInformationPrimary .project-information-subcontent {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-row-gap: 20px;
}

@media screen and (max-width: 600px) {
    .petrolEarnings {
        grid-template-columns: 1fr;
    }

    .projectInformationWrapper {
        padding: 100px 0 50px 0;
    }

    .projectInformation h1 {
        font-size: 2.5em;
    }

    .projectInformation h2 {
        font-size: 1.5em;
        margin: 50px 0 50px 0;
    }

    .projectInformation p {
        font-weight: 300;
        font-size: 1.25em;
    }

    .content-large {
        font-size: 2em !important;
    }

    .content-medium {
        font-size: 1.5em;
    }

    .content-small {
        font-size: 1em;
    }

    .project-information-content {
        grid-template-columns: 100%;
    }
}

@media print {
    .printables-logo {
        display: block;
        height: 30px;
        margin-bottom: 20px;
    }

    .projectInformationWrapper {
        padding: 50px 0 0 0;
    }

    .projectInformation h2 {
        margin: 20px 0 30px 0;
    }

    .timeline-wrapper {
        display: none;
    }

    .timelinePrintables,
    .printables {
        display: grid;
    }

    .visxLegendTitle,
    .visx-legend-label,
    .petrolEarnings h2 {
        color: #000;
    }

    .projectInformationPetrolContainer {
        background: none;
    }

    .projectInformationPetrolContainer .projectInformation h1,
    .projectInformationPetrolContainer .projectInformation p {
        color: #000;
    }
}
