.still-looking {
    display: flex;
    justify-content: center;
    align-items: center;
}

.still-looking img {
    width: 700px;
}

/*
.round-btn {
    background: #FFF;
    border-radius: 50px;
    padding: 8px 15px;
    font-size: 1.125em;
    cursor: pointer;
}

.round-btn-small {
    background: #FFF;
    border-radius: 50px;
    padding: 6px 12px;
    font-size: 1em;
    cursor: pointer;
    color: #000;
}
*/

.projectInformationWrapper .projectInformation {
    background: #FFF;
    padding: 50px;
}

.projectInformationContainer {
    background: url('./assets/images/oie-project_information-bg.svg') no-repeat;
}

.projectLinksWrapper .subtitle {
    margin-top: 50px;
    margin-bottom: 40px;
    font-size: 1.125em;
    font-weight: 400;
}

.projectLinksWrapper a:hover {
    text-decoration: none;
    color: #000;
}

.RoyaltiesStackbar rect {
    border-bottom: 1px solid #F00;
}

.source-wrapper {
    text-align: center;
    font-size: 0.875em;
}

.section-title {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 3em;
    font-weight: 200;
    /* margin-bottom: 50px; */
    /* display: inline-block; */
    /* float: left; */
}

.section-subtitle {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 1.875em;
    font-weight: 500;
    margin-bottom: 50px;
}

.vis {
    margin: 0 auto;
}

.rv-xy-plot {
    position: relative;
}

text {
    fill: #FFF;
    /* font-size: 12px; */
}

.project-module-header {
    display: flex;
    padding-top: 20px;
    height: 100px;
    align-items: center;
    margin-bottom: 30px;
}

.project-module-select {
    float: left;
    padding: 12px 0;
    margin-left: 20px;
}

.hint {
    background: #333;
    color: #CCC;
    padding: 15px;
    border-radius: 5px;
}

.hint h5 {
    font-weight: 500;
    font-size: 1em;
}

.hint p {
    font-size: 0.875em;
}

.hint h5, .hint p {
    margin-bottom: 8px;
}

.hint p:last-child {
    margin-bottom: 0;
}

.download-report-btn {
    display: block;
    background: url('./assets/images/oie-download_report.svg') no-repeat center;
    width: 160px;
    height: 75px;
    background-size: 100%;
    cursor: pointer;
}

.download-report-btn span {
    display: none;
}

.link {
    display: block;
    margin-top: 30px;
    color: #FFF !important;
}

@media screen and (max-width: 600px) {
    .project-module-header {
        flex-direction: column;
        height: auto;
        align-items: flex-start;
    }

    .project-module-select {
        margin-left: 0;
    }
}

@media print {
    .project-module-select {
        display: none;
    }

    .hide-on-print {
        display: none !important;
    }
}
